import type { FC, ComponentType } from 'react'
import * as Icons from '@pleo-io/telescope-icons'

import type { AccountCategoryTypeKey } from '@pleo-io/deimos'

const Categories: Record<AccountCategoryTypeKey | 'empty', { icon: ComponentType<any> }> = {
    entertainment: {
        icon: Icons.CategoryEntertainment,
    },
    equipment_and_hardware: {
        icon: Icons.CategoryEquipment,
    },
    marketing_and_advertising: {
        icon: Icons.CategoryMarketing,
    },
    meals_and_drinks: {
        icon: Icons.CategoryMealsAndDrinks,
    },
    office_expenses: {
        icon: Icons.CategoryOffice,
    },
    phone_and_internet: {
        icon: Icons.CategoryPhoneInternet,
    },
    software: {
        icon: Icons.CategorySoftware,
    },
    travel: {
        icon: Icons.CategoryTravel,
    },
    other: {
        icon: Icons.CategoryOther,
    },
    services_and_consultancy: {
        icon: Icons.CategoryService,
    },
    supplies: {
        icon: Icons.CategorySupplies,
    },
    no_suitable_category: {
        icon: Icons.CategoryIDonTKnow,
    },
    empty: {
        icon: Icons.CategoryNone,
    },
}

interface Props {
    imgkey?: AccountCategoryTypeKey | 'empty'
}

export const CategoryIcon: FC<React.PropsWithChildren<Props>> = ({ imgkey, ...props }) => {
    const { icon: Icon } = Categories[imgkey ?? 'empty']

    return <Icon {...props} />
}
