import { Alert, Button, List, Modal, Space, Spin, Tag } from 'antd'
import type { UnderwritingApplicationFullDto } from 'bff/moons/generated/kale'
import { creditBff } from '../../bff'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useState } from 'react'

interface Props {
    application: UnderwritingApplicationFullDto
    companyId: string
}

export default function OverdraftRequesterDirectorInfo({ application, companyId }: Props) {
    const requesterId = application.stateTrail[0].userId
    const { data, isInitialLoading, isError } = creditBff.getRequesterDirectorInfo.useQuery({
        companyId,
        userId: requesterId,
    })

    const [isDirectorsModalOpen, setIsDirectorsModalOpen] = useState(false)

    if (isInitialLoading) {
        return <Spin />
    }

    if (isError) {
        return <Alert message="Failed" type="error" showIcon />
    }

    return (
        <>
            <Space direction="horizontal">
                {data?.isUserDirector ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}
                <Button
                    icon={<InfoCircleOutlined />}
                    type="link"
                    onClick={() => setIsDirectorsModalOpen(true)}
                >
                    Directors
                </Button>
            </Space>
            <Modal
                open={isDirectorsModalOpen}
                onCancel={() => setIsDirectorsModalOpen(false)}
                footer={null}
                centered
                title="Directors"
            >
                <List
                    itemLayout="horizontal"
                    dataSource={data?.directorInfos ?? []}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta title={item.name} description={item.email} />
                        </List.Item>
                    )}
                />
            </Modal>
        </>
    )
}
