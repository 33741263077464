import { bff } from 'pages/compliance/bff'
import { determineScreeningStatus } from 'pages/compliance/company/people/helpers'
import type { KycCheck, KycResult } from 'types/styx'
import { CompanyKycScreening } from 'pages/compliance/company/shared/company-kyc-screenings/company-kyc-screening'
import { useEffect } from 'react'

interface Props {
    companyId: string
    companyName: string
    companyTradingName: string | null
    hasFdd: boolean
}

export const SanctionsScreening = ({
    companyId,
    companyName,
    companyTradingName,
    hasFdd,
}: Props) => {
    const { data, isLoading, refetch } = bff.companyKycScreening.getSanctions.useQuery({
        companyId,
    })

    useEffect(() => {
        refetch()
    }, [refetch, companyName, companyTradingName])

    const sanctions: KycCheck[] = data ? (data as KycCheck[]) : []
    const loading = !sanctions && isLoading

    const screeningStatus = determineScreeningStatus(
        sanctions,
        companyName,
        companyTradingName,
        hasFdd
    ) as KycResult

    return (
        <CompanyKycScreening
            label="Sanctions"
            companyId={companyId}
            name={companyName}
            data={sanctions}
            result={screeningStatus}
            loading={loading}
        />
    )
}
