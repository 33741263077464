import { Breadcrumb, Button, Dropdown, Space, Tag, Typography } from 'antd'
import { Link } from 'react-router-dom'
import {
    AlertOutlined,
    DownOutlined,
    GroupOutlined,
    HistoryOutlined,
    StopOutlined,
} from '@ant-design/icons'

import { bff } from './bff-hooks'
import { useOddDetailContext } from './context-detail'

import type { AssignedAnalyst } from 'bff/moons/generated/case-manager'
import { StatusTag } from './status-tag'
import { ToggleAssigneeButton } from './toggle-assignee'

const { Title, Paragraph } = Typography

export const Header = () => {
    const { caseId, historyModal } = useOddDetailContext()
    const { orgModal } = useOddDetailContext()
    const { data } = bff.pageHeader.getOdd.useQuery({ caseId })

    const spendIsLive = data?.metadata?.spendStatus === 'LIVE'

    return (
        <Space direction="vertical">
            <Breadcrumbs companyName={data?.companyName} />
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space direction="vertical" size={0}>
                    <Title level={3} style={{ margin: 0 }}>
                        {data?.companyName}
                    </Title>
                    <Paragraph type="secondary">{data?.address}</Paragraph>
                    <Space>
                        {data?.status && <StatusTag status={data.status} />}
                        {data?.metadata && data?.metadata?.spendStatus && (
                            <Tag
                                color={spendIsLive ? 'gold' : 'red'}
                                icon={spendIsLive ? <AlertOutlined /> : <StopOutlined />}
                            >
                                {spendIsLive ? 'Spend Status: LIVE' : 'Spend Status: BLOCKED'}
                            </Tag>
                        )}
                        <User assignee={data?.assignee} />
                    </Space>
                </Space>

                <Space.Compact>
                    {data?.orgCompanies && data.orgCompanies.length > 0 && (
                        <Button icon={<GroupOutlined />} onClick={orgModal.open}>
                            Related cases
                        </Button>
                    )}
                    <Button icon={<HistoryOutlined />} onClick={historyModal.open}>
                        History
                    </Button>
                    <ActionsMenu />
                </Space.Compact>
            </Space>
        </Space>
    )
}

const ActionsMenu = () => {
    const { delayNotificationsModal, extendWalletBlockDeadlineModal } = useOddDetailContext()

    const handleMenuClick = (key: string) => {
        switch (key) {
            case 'DELAY_EMAIL_SCHEDULE':
                delayNotificationsModal.open()
                break
            case 'EXTEND_WALLET_BLOCKED_DEADLINE':
                extendWalletBlockDeadlineModal.open()
                break
            default:
                break
        }
    }

    return (
        <Dropdown
            menu={{
                items: [
                    {
                        key: 'DELAY_EMAIL_SCHEDULE',
                        label: 'Delay Notifications',
                    },
                    {
                        key: 'EXTEND_WALLET_BLOCKED_DEADLINE',
                        label: 'Extend Wallet Block deadline',
                    },
                ],
                onClick: ({ key }) => handleMenuClick(key),
            }}
        >
            <Button>
                <Space>
                    Actions <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    )
}

const Breadcrumbs = ({ companyName }: { companyName?: string }) => {
    return (
        <Breadcrumb>
            <Breadcrumb.Item>
                <Link to={'/compliance'}>Compliance</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                <Link to={'/compliance/odd'}>Ongoing Due Diligence</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{companyName}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

const User = ({ assignee }: { assignee?: AssignedAnalyst }) => {
    const { caseId, isLocked } = useOddDetailContext()

    const caseHandlerInitials = assignee
        ? `${assignee.firstName} ${assignee.lastName}`
              .split(' ')
              .map((name) => name[0])
              .join('')
              .toUpperCase()
        : ''

    return (
        <ToggleAssigneeButton
            id={caseId}
            hasAssignee={Boolean(assignee)}
            caseHandlerInitials={caseHandlerInitials}
            firstName={assignee?.firstName}
            lastName={assignee?.lastName}
            isLocked={isLocked}
        />
    )
}
