import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import CreditHeader from '../components/header'
import { Button, Tabs, Row } from 'antd'
import { PageContentLayout } from 'components/layout-containers'
import CreditOverview from './overview'
import CreditBilling from './billing'
import CreditUnderwriting from './underwriting'
import styled from 'styled-components'

export default function CreditDetails() {
    const { id: companyId } = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const currentPathSegment = location.pathname.split('/').pop()

    const tabList = [
        { key: 'overview', label: 'Overview' },
        { key: 'billing', label: 'Billing' },
        { key: 'eligibility', label: 'Eligibility' },
        { key: 'comms', label: 'Comms' },
        { key: 'underwriting', label: 'Underwriting' },
    ]

    const handleOldCreditNav = () => {
        navigate(`/customer-success/companies/${companyId}/credit`)
    }

    return (
        <StyledPageContentLayout>
            <Row justify={'start'}>
                <Button type="default" onClick={handleOldCreditNav}>
                    Go to old credit
                </Button>
            </Row>
            <CreditHeader />
            <Tabs
                items={tabList}
                defaultActiveKey={currentPathSegment}
                onTabClick={(tabKey: string) => {
                    navigate(tabKey, { replace: true })
                }}
            />
            <Routes>
                <Route path="/" element={<Navigate to="overview" replace />} />
                <Route path="overview" element={<CreditOverview />} />
                <Route path="billing" element={<CreditBilling />} />
                <Route path="eligibility" element={<div>Eligibility</div>} />
                <Route path="comms" element={<div>Comms</div>} />
                <Route path="underwriting" element={<CreditUnderwriting />} />
            </Routes>
        </StyledPageContentLayout>
    )
}

const StyledPageContentLayout = styled(PageContentLayout)`
    padding-bottom: 10rem;
`
