import { Route } from 'react-router-dom'
import { OddListPage } from './screens/odd-list-page'
import { OddDetailPage } from './screens/odd-detail-page'
import { OddAuditHub } from './screens/odd-audit-hub'

export const oddRoutes = (
    <Route>
        <Route index element={<OddListPage />} />
        <Route path="audit-hub" element={<OddAuditHub />} />
        <Route path=":caseId" element={<OddDetailPage />} />
    </Route>
)
