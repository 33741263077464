import { FieldArray, useFormikContext } from 'formik'
import type { FormValuesProfile, Employee, Team } from '../../types'
import { Alert, Button, List, Space } from 'antd'
import ListItem from './list-item'
import { PlusOutlined } from '@ant-design/icons'

const renderListItem =
    (deleteTeam: (index: number) => void) =>
    // eslint-disable-next-line react/display-name
    ({ name, size, reviewerCount }: Team, index: number) => (
        <ListItem
            name={name}
            size={size}
            reviewerCount={reviewerCount}
            index={index}
            deleteTeam={deleteTeam}
        />
    )

const calculateTotalTeamSize = (totalSize: number, current: Team) => {
    const teamSize = current.size + current.reviewerCount
    return totalSize + teamSize
}

const calculateTotalEmployees = (totalSize: number, current: Employee) => {
    return totalSize + current.count
}

const TeamsStep = () => {
    const { values } = useFormikContext<FormValuesProfile>()
    const totalTeamSize = values.teams.reduce(calculateTotalTeamSize, 0)
    const totalEmployees = values.employees.reduce(calculateTotalEmployees, 0)
    const hasTooManyEmployees = totalEmployees > totalTeamSize
    const difference = totalEmployees - totalTeamSize
    const hasDifference = difference !== 0

    return (
        <FieldArray
            name="teams"
            render={(arrayHelpers) => (
                <Space direction="vertical">
                    <List
                        dataSource={values.teams}
                        renderItem={renderListItem(arrayHelpers.remove)}
                        grid={{ column: 1 }}
                    />
                    <Button
                        type="dashed"
                        onClick={() =>
                            arrayHelpers.push({ name: 'New team', size: 0, reviewerCount: 0 })
                        }
                        block
                        icon={<PlusOutlined />}
                    >
                        Add team
                    </Button>
                    {hasDifference &&
                        (!hasTooManyEmployees ? (
                            <Alert
                                showIcon
                                message={`Not enough employees to fill teams, remove ${Math.abs(
                                    difference
                                )} employees.`}
                                type="warning"
                                style={{
                                    marginBottom: '1em',
                                }}
                            />
                        ) : (
                            <Alert
                                showIcon
                                message={`${difference} unassigned employees.`}
                                type="info"
                                style={{
                                    marginBottom: '1em',
                                }}
                            />
                        ))}
                </Space>
            )}
        />
    )
}

export default TeamsStep
