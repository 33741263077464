import type { KycCitizenship, Person } from 'types/styx'

enum CitizenshipSource {
    DUE_DILIGENCE_AGENT_INPUT = 'DUE_DILIGENCE_AGENT_INPUT',
    USER_INPUT = 'USER_INPUT',
    COMPANIES_HOUSE = 'COMPANIES_HOUSE',
    CREDITSAFE_GLOBAL = 'CREDITSAFE_GLOBAL',
    CREDITSAFE_SWEDEN = 'CREDITSAFE_SWEDEN',
    VIRK = 'VIRK',
    VEMBI = 'VEMBI',
    BVD = 'BVD',
}

export interface Citizenship {
    citizenship: string
    source: CitizenshipSource
}

export const flattenKycCitizenship = (person: Person): Citizenship[] =>
    person.kycCitizenships
        ?.flatMap((citizenships) =>
            citizenships.citizenships.map((citizenship) => ({
                source: citizenships.source as CitizenshipSource,
                citizenship,
            }))
        )
        .sort((a, b) => (a.citizenship.toLowerCase() > b.citizenship.toLowerCase() ? 1 : -1)) ?? []

export const filterCitizenships = (
    person: Person,
    predicate: (citizenship: Citizenship) => boolean
): Citizenship[] => flattenKycCitizenship(person).filter((value) => predicate(value)) ?? []

export const transformCitizenshipsToKycCitizenships = (
    citizenships: Citizenship[]
): KycCitizenship[] => {
    return Object.values(
        citizenships.reduce(
            (result, item) => {
                const { source, citizenship } = item

                if (!result[source]) {
                    result[source] = { source, citizenships: [] }
                }

                result[source].citizenships.push(citizenship)

                return result
            },
            {} as { [key: string]: KycCitizenship }
        )
    )
}

export const mapValuesToCitizenship = (
    formValues: string[],
    citizenshipsWithUserInputSource: Citizenship[]
): Citizenship[] => {
    const currentUserProvidedCitizenshipsParam = citizenshipsWithUserInputSource.map(
        (citizenship) => citizenship.citizenship
    )

    return formValues.map((citizenship: string) => ({
        source: currentUserProvidedCitizenshipsParam.includes(citizenship)
            ? CitizenshipSource.USER_INPUT
            : CitizenshipSource.DUE_DILIGENCE_AGENT_INPUT,
        citizenship: citizenship,
    }))
}

export const isUserInput = (citizenship: Citizenship) =>
    citizenship.source === CitizenshipSource.USER_INPUT

export const isDueDiligenceAgentInput = (citizenship: Citizenship) =>
    citizenship.source === CitizenshipSource.DUE_DILIGENCE_AGENT_INPUT

export const isNotDueDiligenceAgentInput = (citizenship: Citizenship) =>
    !isDueDiligenceAgentInput(citizenship)

export const getCitizenshipSourceLabel = (citizenship: Citizenship) =>
    isUserInput(citizenship)
        ? 'User provided'
        : isDueDiligenceAgentInput(citizenship)
          ? 'Manually added'
          : 'Registry'
