import { buildFetcher, kerberosRequest } from 'services/request'
import useSWR from 'swr'
import type { User } from 'types/user'
import { RoleType } from 'types/user-role'

export const getUserTypeDisplay = (user?: User | null) => {
    const userTypes = getUserTypes(user)
    const types: string[] = []
    if (userTypes.isAdmin) {
        types.push('Admin')
    } else if (userTypes.isCompanyMember) {
        types.push('Employee')
    }
    if (userTypes.isPartnerAdmin) {
        types.push('PartnerAdmin')
    } else if (userTypes.isPartnerEmployee) {
        types.push('PartnerEmployee')
    } else if (userTypes.isBookkeeper) {
        types.push('Bookkeeper')
    }
    return types.join(', ')
}

export const getUserTypes = (user?: User | null) => {
    if (!user) {
        return {}
    }
    const isBackofficeUser = user.admin
    const isAdmin =
        user?.roles.filter((r) => r.resource === 'company' && r.type === RoleType.OWNER).length > 0
    const isCompanyMember =
        user?.roles.filter((r) => r.resource === 'company' && r.type === RoleType.MEMBER).length > 0
    const isEmployee =
        user?.roles.filter(
            (r) =>
                r.parentResource === 'company' &&
                r.resource === 'employee' &&
                r.type === RoleType.OWNER
        ).length > 0
    const isBookkeeper =
        user?.roles.filter(
            (r) =>
                r.resource === 'company' &&
                [RoleType.BOOKKEEPER_BASIC, RoleType.BOOKKEEPER_EXTENDED].includes(
                    r.type as RoleType
                )
        ).length > 0
    const isPartnerAdmin =
        user?.roles.filter((r) => r.resource === 'partner' && r.type === RoleType.OWNER).length > 0
    const isPartnerEmployee =
        user?.roles.filter(
            (r) =>
                r.parentResource === 'partner' &&
                r.resource === 'employee' &&
                r.type === RoleType.OWNER
        ).length > 0

    return {
        isBackofficeUser,
        isAdmin,
        isCompanyMember,
        isEmployee,
        isBookkeeper,
        isPartnerAdmin,
        isPartnerEmployee,
    }
}

export const getUserName = (user: User) => {
    const userName =
        `${user.data?.profile?.name?.firstName || ''} ${
            user.data?.profile?.name?.lastName || ''
        }`.trim() || 'Unknown'
    if (!user.verified) return `${userName || 'Unknown'} (Pending)`
    return userName
}

const fetcher = buildFetcher(kerberosRequest)

export const useUserById = (userId?: string | null) => {
    const result = useSWR<User[]>(
        userId ? `api/v1/admin/users?query=&admin=true&userId=${userId}` : null,
        fetcher,
        {
            revalidateOnFocus: true,
        }
    )

    return result
}
