export const isUuid = (id: string | undefined | null) => {
    if (!id) {
        return false
    }

    return (
        id
            // https://stackoverflow.com/a/6640851
            .match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i) !== null
    )
}
