import { Button, Space } from 'antd'

import { bff } from './bff-hooks'
import { useOddDetailContext } from './context-detail'
import { MailOutlined } from '@ant-design/icons'
import { CustomActionId } from '../../pages/compliance/odd/types'

export const CustomActionsList = () => {
    const { caseId, sendRequestInformationModal, walletBlockedModal } = useOddDetailContext()

    const { data: actions } = bff.customActionsList.getOddCustomActionsForCase.useQuery(
        { caseType: 'ODD', caseId },
        { enabled: !!caseId }
    )

    const customActionButtons = [
        {
            key: CustomActionId.SCHEDULE_REQUEST_INFO_EMAILS,
            render: (key: CustomActionId) => (
                <Button
                    key={key}
                    icon={<MailOutlined />}
                    disabled={!actions?.data?.find((action) => action.actionId === key)?.isAllowed}
                    onClick={sendRequestInformationModal.open}
                >
                    Request Information
                </Button>
            ),
        },
        {
            key: CustomActionId.SEND_WALLET_BLOCKED_EMAIL,
            render: (key: CustomActionId) => (
                <Button
                    key={key}
                    icon={<MailOutlined />}
                    disabled={!actions?.data?.find((action) => action.actionId === key)?.isAllowed}
                    onClick={walletBlockedModal.open}
                >
                    Send Wallet Blocked Email
                </Button>
            ),
        },
    ]

    const availableActions = actions?.data?.map((action) => {
        const customAction = customActionButtons.find(({ key }) => key === action?.actionId)

        return customAction
    })

    return (
        <Space
            style={{ width: '100%', display: 'flex', flexFlow: 'row wrap', marginRight: '1rem' }}
        >
            {availableActions &&
                availableActions.length > 0 &&
                availableActions.map((action) => action?.render(action?.key))}
        </Space>
    )
}
