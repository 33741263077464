import type { CompanyEntitlementsTableInfo } from '../backend/entitlements.bff'
import { Alert, Button, Descriptions, Modal, Table, type TableColumnsType, Typography } from 'antd'
import styled, { css } from 'styled-components'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { green, red } from '@ant-design/colors'
import { type FC, useState } from 'react'

const { Text } = Typography

const TableStyleProvider = styled('div')`
    border-top: 1px solid #f0f0f0;
`
const StyledDescriptions = styled(Descriptions)`
    width: 30vw;
    display: inline-block;
`

const AlertBase = css`
    display: inline-flex;
    padding: 4px 10px;
    .ant-alert-message {
        color: inherit;
    }
`

const On = styled(Alert).attrs({
    type: 'success',
    message: 'On',
    icon: <CheckOutlined />,
    showIcon: true,
})`
    ${AlertBase};
    color: ${green[5]};
`
const Off = styled(Alert).attrs({
    type: 'error',
    message: 'Off',
    icon: <CloseOutlined />,
    showIcon: true,
})`
    ${AlertBase};
    color: ${red[5]};
`

export interface Props {
    data: CompanyEntitlementsTableInfo[] | undefined
    isLoading: boolean
}

export const CompanyEntitlementsTable: FC<React.PropsWithChildren<Props>> = ({
    data,
    isLoading,
}) => {
    const [overviewModalActive, setOverviewModalActive] = useState(false)
    const [overviewEntitlementInfo, setOverviewEntitlementInfo] =
        useState<CompanyEntitlementsTableInfo | null>(null)

    const onOverview = (info: CompanyEntitlementsTableInfo) => {
        setOverviewEntitlementInfo(info)
        setOverviewModalActive(true)
    }

    const columns: TableColumnsType<CompanyEntitlementsTableInfo> = [
        {
            key: 'entitlementName',
            dataIndex: 'entitlementName',
            render: (entitlementName: string, tableInfo: CompanyEntitlementsTableInfo) => (
                <Button
                    data-testid="actions-button"
                    type="dashed"
                    onClick={() => onOverview(tableInfo)}
                >
                    {entitlementName}
                </Button>
            ),
            title: 'Entitlements',
        },
        {
            key: 'currentEntitlements',
            dataIndex: 'isEnabledOrLimitValue',
            align: 'center',
            render: (isEnabledOrLimitValue?: boolean | number) => {
                if (typeof isEnabledOrLimitValue === 'boolean') {
                    return isEnabledOrLimitValue ? <On /> : <Off />
                }
                return isEnabledOrLimitValue
            },
            title: 'Entitled by plan',
        },
        {
            key: 'overrideEntitlements',
            dataIndex: 'overrideIsEnabledOrLimitValue',
            align: 'center',
            render: (overrideIsEnabledOrLimitValue?: boolean | number) => {
                if (typeof overrideIsEnabledOrLimitValue === 'boolean') {
                    return overrideIsEnabledOrLimitValue ? <On /> : <Off />
                }
                return overrideIsEnabledOrLimitValue
            },
            title: 'Entitled by overrides',
        },
        {
            key: 'temporaryEntitlements',
            dataIndex: 'temporaryIsEnabled',
            align: 'center',
            render: (temporaryIsEnabled?: boolean) => {
                if (temporaryIsEnabled) {
                    return <On />
                }
            },
            title: 'Entitled by temporary plan',
        },
    ]

    return (
        <TableStyleProvider>
            <Table
                columns={columns}
                dataSource={data}
                loading={isLoading}
                size="small"
                rowKey={'id'}
                data-testid="company-entitlements-test-id"
                pagination={{
                    pageSize: 100,
                }}
                scroll={{ x: 'max-content' }}
            />
            <Modal
                title={overviewEntitlementInfo?.entitlementName}
                open={overviewModalActive}
                width="50"
                centered
                footer={null}
                onCancel={() => setOverviewModalActive(false)}
            >
                <div>
                    <StyledDescriptions column={1}>
                        <Descriptions.Item label="Description">
                            <Text>{overviewEntitlementInfo?.description}</Text>
                        </Descriptions.Item>
                        <Descriptions.Item label="Owner">
                            <Text>{overviewEntitlementInfo?.owner}</Text>
                        </Descriptions.Item>
                    </StyledDescriptions>
                </div>
            </Modal>
        </TableStyleProvider>
    )
}
