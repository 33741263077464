import type {
    PlanResponse,
    FeatureResponse,
    BulkUpdatePlanParams,
} from 'bff/moons/generated/janus-v1'
import type { Column, LimitResponse, Record, TableCellProps, FormikStructure } from '../types'
import { ViewType } from '../../enums'
import { merge } from 'lodash'
import { allKey, minColumnWidth } from './constants'
import {
    combineFeatureFieldsForUpdate,
    filterAllFromFormValues,
    filterFieldForDataIndex,
    generateEntitlementTableData,
    generateLimitTableData,
    getLimitsForUpdate,
    getUpdatedColumns,
    getValueForFields,
    preparePlanForUpdate,
} from './utils'
import { bff } from '../../bff'

export const generateColumns = (
    currentView: ViewType,
    plans: PlanResponse[],
    isEditing: boolean,
    onFieldUpdate: TableCellProps['onFieldUpdate']
): Column[] => {
    const baseColumns = [
        {
            title: '',
            dataIndex: 'label',
            key: 'label',
            width: minColumnWidth,
            editable: false,
        },
        ...(isEditing
            ? [
                  {
                      title: `All selected ${
                          currentView === ViewType.Market ? 'plans' : 'countries'
                      }`,
                      dataIndex: allKey,
                      key: allKey,
                      editable: true,
                      minWidth: minColumnWidth,
                  },
              ]
            : []),
        ...plans.map((plan) =>
            currentView === ViewType.Plan
                ? {
                      title: `${plan.country} - ${plan.priceGeneration}`,
                      dataIndex: plan.country,
                      key: plan.country,
                      editable: true,
                      minWidth: minColumnWidth,
                  }
                : {
                      title: `${plan.name} - ${plan.priceGeneration}`,
                      dataIndex: plan.name,
                      key: plan.name,
                      editable: true,
                      minWidth: minColumnWidth,
                  }
        ),
    ]

    return baseColumns.map((col) => {
        return {
            ...col,
            onCell: (record: Record) => ({
                record,
                dataIndex: col.dataIndex,
                title: col.title,
                editable: col.editable,
                onFieldUpdate: onFieldUpdate,
                editing: isEditing,
            }),
        }
    })
}

export const generateDataSource = (
    entitlements: Array<FeatureResponse>,
    limits: Array<LimitResponse>,
    plans: PlanResponse[],
    currentView: ViewType,
    scheduledPlans: PlanResponse[]
): Record[] | [] => {
    const tableEntitlements = generateEntitlementTableData(
        entitlements,
        plans,
        currentView,
        scheduledPlans
    )
    const tableLimits = generateLimitTableData(limits, plans, currentView)
    const entitlementsAndLimits = merge({}, tableEntitlements, tableLimits)
    const dataSource = Object.values(entitlementsAndLimits ?? {})

    return dataSource ?? []
}

export const generateUpdatedPlansFromForm = (
    formValues: FormikStructure,
    plans: PlanResponse[],
    currentView: ViewType
): BulkUpdatePlanParams[] => {
    const filteredValues = filterAllFromFormValues(formValues)
    const columnKeysToUpdate = getUpdatedColumns(filteredValues)
    const plansWithUpdates = plans.filter(({ country, name }) => {
        const valueToCheck = currentView === ViewType.Plan ? country : name
        return columnKeysToUpdate.some((key) => valueToCheck.includes(key))
    })
    return plansWithUpdates.map((plan) =>
        mapPlanFieldsForUpdate(filteredValues, currentView, plan, formValues)
    )
}

export const useGetScheduledPlans = (plans: PlanResponse[]) => {
    const currentScheduledPlanIds = plans.flatMap(
        ({ scheduledChanges }) => scheduledChanges?.map(({ planId }) => planId) ?? []
    )
    const { data = [] } = bff.plans.getPlansById.useQuery({
        planIds: currentScheduledPlanIds,
    })
    return data
}

function mapPlanFieldsForUpdate(
    filteredValues: string[],
    currentView: ViewType,
    plan: PlanResponse,
    formValues: FormikStructure
) {
    const updatedFieldsForPlan = filterFieldForDataIndex(
        filteredValues,
        currentView === ViewType.Plan ? plan.country : plan.name
    )
    const updatedPlanFields = getValueForFields(updatedFieldsForPlan, formValues)
    const [updatedFeatureIds, updatedAddonIds] = combineFeatureFieldsForUpdate(
        plan,
        updatedPlanFields
    )
    const updatedLimits = getLimitsForUpdate(updatedPlanFields)
    return preparePlanForUpdate(plan, updatedFeatureIds, updatedAddonIds, updatedLimits)
}
