import styled from 'styled-components'

import { PageContentLayout } from 'components/layout-containers'

import { OddDetailProvider } from 'features/odd/context-detail'
import { Header } from 'features/odd/page-header'
import { CheckList } from 'features/odd/checks'
import { Evidence } from 'features/odd/evidence'
import { CaseLockedMessage } from 'features/odd/case-locked-message'
import {
    ConclusionModal,
    DelayNotificationsModal,
    HistoryModal,
    OrgModal,
    OffboardingModal,
    SendRequestInformationModal,
    NotesModal,
    WalletBlockedModal,
    ExtendWalletBlockDeadline,
} from 'features/odd/modals'
import { WalletBlockedWarning } from 'features/odd/wallet-blocked-warning'
import Notes from 'features/odd/notes'
import OffboardingDetails from 'features/odd/offboarding-details'

export const OddDetailPage = () => {
    return (
        <OddDetailProvider>
            <PageContentLayout>
                <Header />
                <CaseLockedMessage />
                <WalletBlockedWarning />
                <Container>
                    <LeftStack>
                        <CheckList />
                        <Notes />
                        <OffboardingDetails />
                    </LeftStack>
                    <Evidence />
                </Container>
            </PageContentLayout>
            <OffboardingModal />
            <SendRequestInformationModal />
            <DelayNotificationsModal />
            <ExtendWalletBlockDeadline />
            <ConclusionModal />
            <HistoryModal />
            <OrgModal />
            <NotesModal />
            <WalletBlockedModal />
        </OddDetailProvider>
    )
}

const Container = styled('div')`
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
`

const LeftStack = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 25%;
`
