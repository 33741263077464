import type { SelectProps } from 'antd'
import { ViewType } from '../enums'
import { FilterLabel, FilterName, FilterType } from './enums'

type Option = {
    value: string
    label: string
}

const mapStringToOptions = (value: string): Option => ({
    value,
    label: value,
})

const generateComboboxFilter = (filter: SearchFilter): SearchFilter => ({
    ...filter,
    type: FilterType.ComboBox,
    label: `${filter.label}s`,
    mode: 'multiple' as const,
})

export const comboboxFilterIndex = 1

export type SearchFilter = {
    label: string
    options: { value: string; label: string }[]
    type: FilterType
    name: FilterName
    mode?: SelectProps['mode']
}

export type GetSearchFiltersParams = {
    plans?: string[]
    markets?: string[]
    priceGenerations?: string[]
    currentView: ViewType
}

export const getSearchFilters = ({
    plans,
    markets,
    priceGenerations,
    currentView,
}: GetSearchFiltersParams): SearchFilter[] => {
    const marketFilter: SearchFilter = {
        label: FilterLabel.Market,
        name: FilterName.Market,
        options: markets?.map(mapStringToOptions) ?? [],
        type: FilterType.Select,
    }
    const planFilter: SearchFilter = {
        label: FilterLabel.Plan,
        name: FilterName.Plan,
        options: plans?.map(mapStringToOptions) ?? [],
        type: FilterType.Select,
    }
    const priceGenerationFilter: SearchFilter = {
        label: `${FilterLabel.PriceGeneration}(s)`,
        name: FilterName.PriceGeneration,
        options: priceGenerations?.map(mapStringToOptions) ?? [],
        type: FilterType.ComboBox,
        mode: 'multiple' as const,
    }

    const filterOrder =
        currentView === ViewType.Market
            ? [marketFilter, planFilter, priceGenerationFilter]
            : [planFilter, marketFilter, priceGenerationFilter]

    if (filterOrder[comboboxFilterIndex]) {
        filterOrder[comboboxFilterIndex] = generateComboboxFilter(filterOrder[comboboxFilterIndex])
    }

    return filterOrder
}

export const verticalFormFieldProps = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
}
