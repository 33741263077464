import useSWR from 'swr'
import { fetcher } from 'services/styx/request'
import type { ErrorResponse } from '@pleo-io/deimos'
import type { KycUserResponse } from 'types/styx'

export const useKycUserResponse = (companyId: string) => {
    const url = `rest/v2/kyc-user-responses/companies/${companyId}`

    const response = useSWR<KycUserResponse, ErrorResponse>(url, fetcher, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
    })

    return response
}
