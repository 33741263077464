import { bff } from './bff-hooks'
import { useState } from 'react'
import { Button, Checkbox, Col, DatePicker, Input, Row, Select, Spin } from 'antd'
import type { FilterParams } from 'pages/compliance/odd/utils'
import { ReminderStatusType, SearchParamType, FilterParamType } from 'pages/compliance/odd/types'
import type { SearchCasesParams } from 'bff/moons/generated/case-manager'
import { useCountriesList } from 'utils/countries'
import type { Alpha2Code } from 'types/countries'
import { countries } from 'countries-list'
import dayjs from 'dayjs'
import { RiskScore } from 'types/styx'

const { RangePicker } = DatePicker

type FilterBarProps = {
    activeTab: string
    updateUrl: (params: FilterParams) => void
    filters: FilterParams
    updateSearchParams: (params: SearchCasesParams) => void
    clearParams: () => void
}

export const FilterBar = ({
    activeTab,
    updateUrl,
    updateSearchParams,
    filters,
    clearParams,
}: FilterBarProps) => {
    const signupCountries = useCountriesList()
    const [kerberosEmailSearch, setKerberosEmailSearch] = useState<string | undefined>()
    const { data: kerberosUsers, isLoading: isLoadingKerberosUsers } =
        bff.filters.getKerberosUsers.useQuery({ admin: true, query: kerberosEmailSearch })

    const getCountry = (alpha2code: Alpha2Code) => countries[alpha2code as Alpha2Code]

    const timeRangePreselects = [
        {
            key: 'overdue',
            label: 'Overdue',
            dueDateFrom: undefined,
            dueDateTo: dayjs().format('YYYY-MM-DD'),
            due_from: undefined,
            due_to: dayjs().toISOString(),
        },
        {
            key: 'upcomingWeek',
            label: 'Select upcoming week',
            dueDateFrom: dayjs().format('YYYY-MM-DD'),
            dueDateTo: dayjs().add(7, 'D').format('YYYY-MM-DD'),
            due_from: dayjs().toISOString(),
            due_to: dayjs().add(7, 'D').toISOString(),
        },
        {
            key: 'upcomingMonth',
            label: 'Select upcoming month',
            dueDateFrom: dayjs().format('YYYY-MM-DD'),
            dueDateTo: dayjs().add(1, 'M').format('YYYY-MM-DD'),
            due_from: dayjs().toISOString(),
            due_to: dayjs().add(1, 'M').toISOString(),
        },
        {
            key: 'upcomingQuarter',
            label: 'Select upcoming quarter (next 3 months)',
            dueDateFrom: dayjs().format('YYYY-MM-DD'),
            dueDateTo: dayjs().add(3, 'M').format('YYYY-MM-DD'),
            due_from: dayjs().toISOString(),
            due_to: dayjs().add(3, 'M').toISOString(),
        },
        {
            key: 'notDueYet',
            label: 'Not due yet',
            dueDateFrom: dayjs().format('YYYY-MM-DD'),
            dueDateTo: undefined,
            due_from: dayjs().toISOString(),
            due_to: undefined,
        },
    ]

    return (
        <Row gutter={[8, 8]} style={{ marginBottom: '1rem' }} justify={'space-between'}>
            <Col span={4}>
                <Select
                    placeholder="Country"
                    options={signupCountries?.map(({ countryCode }) => ({
                        value: countryCode,
                        label: `${getCountry(countryCode).emoji} ${getCountry(countryCode).name}`,
                    }))}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                        updateUrl({ [FilterParamType.country]: value })
                        updateSearchParams({
                            [SearchParamType.metadata]: JSON.stringify({
                                companyName: filters?.companyName || undefined,
                                companyCountryCode: value || undefined,
                                previousRiskScore: filters?.previousRiskScore || undefined,
                                waitStatus: filters?.waitStatus || undefined,
                            }),
                        })
                    }}
                    allowClear
                    value={filters?.country}
                />
            </Col>
            <Col span={4}>
                <Input
                    placeholder="Company ID"
                    onChange={(e) => {
                        updateUrl({ [FilterParamType.entityId]: e.target.value })
                        updateSearchParams({ [SearchParamType.entityId]: e.target.value })
                    }}
                    allowClear
                    value={filters?.entityId}
                    style={{ width: '100%' }}
                />
            </Col>
            <Col span={4}>
                <Input
                    placeholder="Company Name"
                    onChange={(e) => {
                        updateUrl({ [FilterParamType.companyName]: e.target.value })
                        updateSearchParams({
                            [SearchParamType.metadata]: JSON.stringify({
                                companyCountryCode: filters?.country || undefined,
                                companyName: e.target.value || undefined,
                                previousRiskScore: filters?.previousRiskScore || undefined,
                                waitStatus: filters?.waitStatus || undefined,
                            }),
                        })
                    }}
                    allowClear
                    value={filters?.companyName}
                    style={{ width: '100%' }}
                />
            </Col>
            <Col span={4}>
                <Select
                    placeholder="Previous Risk Score"
                    options={Object.values(RiskScore).map((riskScore) => ({
                        value: riskScore,
                        label: riskScore,
                    }))}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                        updateUrl({ [FilterParamType.previousRiskScore]: value })
                        updateSearchParams({
                            [SearchParamType.metadata]: JSON.stringify({
                                companyCountryCode: filters?.country || undefined,
                                companyName: filters?.companyName || undefined,
                                previousRiskScore: value || undefined,
                                waitStatus: filters?.waitStatus || undefined,
                            }),
                        })
                    }}
                    allowClear
                    value={filters?.previousRiskScore}
                />
            </Col>
            <Col span={4}>
                <Select
                    placeholder="Select time range"
                    options={timeRangePreselects?.map((preselect) => ({
                        value: preselect?.key,
                        label: preselect?.label,
                    }))}
                    style={{ width: '100%' }}
                    onChange={(value) => {
                        updateUrl({
                            [FilterParamType.dueDateFrom]: timeRangePreselects.find(
                                (preselect) => preselect.key === value
                            )?.dueDateFrom,
                            [FilterParamType.dueDateTo]: timeRangePreselects.find(
                                (preselect) => preselect.key === value
                            )?.dueDateTo,
                            [FilterParamType.preselectTimeRange]: value || undefined,
                        })
                        updateSearchParams({
                            [SearchParamType.due_from]: timeRangePreselects.find(
                                (preselect) => preselect.key === value
                            )?.due_from,
                            [SearchParamType.due_to]: timeRangePreselects.find(
                                (preselect) => preselect.key === value
                            )?.due_to,
                        })
                    }}
                    allowClear
                    value={filters?.preselectTimeRange}
                />
            </Col>
            <Col span={4}>
                <Select
                    showSearch
                    placeholder="Assignee"
                    style={{ width: '100%' }}
                    labelInValue
                    filterOption={false}
                    onSearch={(value) => setKerberosEmailSearch(value)}
                    notFoundContent={isLoadingKerberosUsers ? <Spin size="small" /> : null}
                    options={kerberosUsers?.map((user: any) => ({
                        value: user.id,
                        label: user.email,
                    }))}
                    onChange={(assignee) => {
                        updateUrl({ [FilterParamType.assigneeId]: assignee?.value })
                        updateSearchParams({ [SearchParamType.assigneeId]: assignee?.value })
                    }}
                    allowClear
                    value={filters?.assigneeId ? { value: filters?.assigneeId } : undefined}
                />
            </Col>
            <Col span={4}>
                <Select
                    showSearch
                    placeholder="Last assigned to"
                    style={{ width: '100%' }}
                    labelInValue
                    filterOption={false}
                    onSearch={(value) => setKerberosEmailSearch(value)}
                    notFoundContent={isLoadingKerberosUsers ? <Spin size="small" /> : null}
                    options={kerberosUsers?.map((user: any) => ({
                        value: user.id,
                        label: user.email,
                    }))}
                    onChange={(assignee) => {
                        updateUrl({ [FilterParamType.lastAssigneeId]: assignee?.value })
                        updateSearchParams({
                            [SearchParamType.lastAssigneeId]: assignee?.value,
                        })
                    }}
                    allowClear
                    value={filters?.lastAssigneeId ? { value: filters?.lastAssigneeId } : undefined}
                />
            </Col>
            <Col span={4}>
                <Select
                    placeholder="Wait Status"
                    style={{ width: '100%' }}
                    options={[
                        {
                            value: ReminderStatusType.WALLET_BLOCKED_DUE,
                            label: 'Wallet Blocked Due',
                        },
                        { value: ReminderStatusType.REMINDER_2, label: '2nd Reminder' },
                        { value: ReminderStatusType.REMINDER_1, label: '1st Reminder' },
                        { value: ReminderStatusType.INITIAL, label: 'Initial' },
                    ]}
                    onChange={(value) => {
                        updateUrl({ [FilterParamType.waitStatus]: value })
                        updateSearchParams({
                            [SearchParamType.metadata]: JSON.stringify({
                                companyCountryCode: filters?.country || undefined,
                                companyName: filters?.companyName || undefined,
                                previousRiskScore: filters?.previousRiskScore || undefined,
                                waitStatus: value || undefined,
                            }),
                        })
                    }}
                    allowClear
                    value={filters?.waitStatus}
                    disabled={activeTab === 'walletBlockedDue'}
                />
            </Col>
            <Col span={8}>
                <RangePicker
                    placeholder={['Assigned at from', 'Assigned at to']}
                    value={[
                        filters?.assignedAtFrom ? dayjs(filters?.assignedAtFrom) : undefined,
                        filters?.assignedAtTo ? dayjs(filters?.assignedAtTo) : undefined,
                    ]}
                    style={{ width: '100%' }}
                    onChange={(values) => {
                        updateUrl({
                            [FilterParamType.assignedAtFrom]: values
                                ? dayjs(values[0]).format('YYYY-MM-DD')
                                : undefined,
                            [FilterParamType.assignedAtTo]: values
                                ? dayjs(values[1]).format('YYYY-MM-DD')
                                : undefined,
                        })
                        updateSearchParams({
                            [SearchParamType.assigned_at_from]: values
                                ? values[0]?.startOf('D').toISOString()
                                : undefined,
                            [SearchParamType.assigned_at_to]: values
                                ? values[1]?.endOf('D').toISOString()
                                : undefined,
                        })
                    }}
                />
            </Col>
            <Col span={8}>
                <RangePicker
                    placeholder={['Due date from', 'Due date to']}
                    value={[
                        filters?.dueDateFrom ? dayjs(filters?.dueDateFrom) : undefined,
                        filters?.dueDateTo ? dayjs(filters?.dueDateTo) : undefined,
                    ]}
                    onChange={(values) => {
                        updateUrl({
                            [FilterParamType.dueDateFrom]: values
                                ? dayjs(values[0]).format('YYYY-MM-DD')
                                : undefined,
                            [FilterParamType.dueDateTo]: values
                                ? dayjs(values[1]).format('YYYY-MM-DD')
                                : undefined,
                        })
                        updateSearchParams({
                            [SearchParamType.due_from]: values
                                ? values[0]?.startOf('D').toISOString()
                                : undefined,
                            [SearchParamType.due_to]: values
                                ? values[1]?.endOf('D').toISOString()
                                : undefined,
                        })
                    }}
                    style={{ width: '100%' }}
                />
            </Col>
            <Col
                span={4}
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
            >
                <Checkbox
                    onChange={(e) => {
                        updateUrl({ [FilterParamType.unassigned_only]: e.target.checked })
                        updateSearchParams({
                            [SearchParamType.unassigned_only]: e.target.checked,
                        })
                    }}
                    checked={
                        filters?.unassigned_only === undefined
                            ? false
                            : Boolean(filters?.unassigned_only)
                    }
                    style={{ width: '100%' }}
                >
                    Unassigned only
                </Checkbox>
            </Col>
            <Col span={4} style={{ alignSelf: 'flex-end' }}>
                <Button style={{ width: '100%' }} onClick={() => clearParams()}>
                    Reset filters
                </Button>
            </Col>
        </Row>
    )
}
