import {
    Badge,
    Descriptions,
    Divider,
    Form,
    Input,
    List,
    Modal,
    Space,
    Tag,
    Typography,
} from 'antd'
import type { ExportHistoryType } from './export-history'
import dayjs from 'packages/dayjs'
import { SyncOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { JobStatus } from '@pleo-io/deimos'
import { capitalize, replace } from 'lodash'
import { useMemo, useState } from 'react'
import useSWR from 'swr'
import { buildFetcher, exportRequest } from 'services/request'
import { bff } from '../bff-hooks'

const { Text, Title } = Typography

const useGetExportJobItems = (exportHistoryId: string) => {
    const { data, error } = useSWR(
        exportHistoryId ? `rest/v2/export-jobs/${exportHistoryId}/items` : null,
        buildFetcher(exportRequest)
    )

    return {
        data,
        error,
    }
}

export const ExportHistoryModal = ({
    exportHistory,
    shouldUseDeimos,
    onCancel,
}: {
    exportHistory: ExportHistoryType
    shouldUseDeimos: boolean
    onCancel: () => void
}) => {
    const [expenseIdToFind, setExpenseIdToFind] = useState<string | undefined>()
    const { data: exportJobItems } = useGetExportJobItems(exportHistory?.id || '')

    if (!exportHistory) {
        return null
    }

    const exportExpenses =
        exportJobItems?.data?.map((expense: any) => {
            return {
                expense_id: expense.accountingEntryId,
                external_id: expense.externalId,
                external_url: expense.externalUrl,
                failure: expense.status === 'failed' || expense.status === 'abandoned',
            }
        }) || []

    const failedExportItems =
        exportJobItems?.data?.reduce((acc: any, expense: any) => {
            if (expense.status === 'failed' || expense.status === 'abandoned') {
                acc.push({
                    message: expense.failureReason,
                    expense_id: expense.accountingEntryId,
                    failure_reason: expense.failureReasonType,
                })
            }
            return acc
        }, []) || []

    if (!shouldUseDeimos) {
        exportHistory = {
            ...exportHistory,
            stats: {
                expenses: exportExpenses,
                failures: failedExportItems,
            },
        }
    }

    return (
        <Modal
            title={`Export created at ${dayjs(exportHistory?.createdAt).format('lll')}`}
            open={exportHistory}
            onCancel={onCancel}
            footer={null}
            width={640}
        >
            <Descriptions column={1} style={{ margin: '0 -3px' }}>
                <Descriptions.Item label="Status">
                    <StatusTag status={exportHistory?.status} />
                </Descriptions.Item>
                <Descriptions.Item label="Export Type">{exportHistory?.type}</Descriptions.Item>
                <Descriptions.Item label="Export History ID">{exportHistory?.id}</Descriptions.Item>
                <Descriptions.Item label="Created at">
                    {dayjs(exportHistory?.createdAt).format('lll')}
                </Descriptions.Item>
                <Descriptions.Item label="Last updated at">
                    {dayjs(exportHistory?.updatedAt).format('lll')}
                </Descriptions.Item>
                <Descriptions.Item label="Employee">
                    <Employee employeeId={exportHistory?.employeeId} />
                </Descriptions.Item>
                <Descriptions.Item label="Includes previously exported expenses?">
                    {exportHistory?.stats?.params?.reexport ? ' Yes' : 'No'}
                </Descriptions.Item>
            </Descriptions>
            <Divider />
            <Title
                level={5}
            >{`Exported expenses (${exportHistory?.stats?.expenses?.length})`}</Title>
            {exportHistory?.stats?.expenses?.length > 0 ? (
                <>
                    <Form.Item style={{ margin: '8px 0 8px' }}>
                        <Input
                            placeholder="Search for an expense by entering an expense ID"
                            onChange={(e) => setExpenseIdToFind(e.target.value)}
                            allowClear
                        />
                    </Form.Item>
                    <List
                        style={{ maxHeight: '240px', overflowX: 'auto', position: 'relative' }}
                        bordered
                        size="small"
                        dataSource={exportHistory?.stats?.expenses?.filter(
                            (expense: ExportedExpense) =>
                                expenseIdToFind
                                    ? expense.expense_id.includes(expenseIdToFind)
                                    : true
                        )}
                        renderItem={(item: ExportedExpense) => (
                            <List.Item>
                                <Text copyable>{item.expense_id}</Text>
                                {item.failure ? <CloseCircleOutlined /> : <CheckCircleOutlined />}
                            </List.Item>
                        )}
                    />
                </>
            ) : (
                <Text type="secondary">No expenses were included in this export.</Text>
            )}
            <Divider />
            <Title level={5}>Errors</Title>
            {exportHistory?.stats?.failures ? (
                <List
                    style={{ maxHeight: '240px', overflowX: 'auto', position: 'relative' }}
                    bordered
                    size="small"
                    dataSource={exportHistory?.stats?.failures}
                    renderItem={(item: ExportFailure) => (
                        <List.Item>
                            <Space direction="vertical" style={{ width: '100%' }}>
                                {item?.message && <Badge status="error" text={item.message} />}
                                {(item?.expense_id || item?.failure_reason) && (
                                    <>
                                        <div
                                            style={{
                                                justifyContent: 'space-between',
                                                display: 'flex',
                                                flexFlow: 'row',
                                            }}
                                        >
                                            {item?.expense_id && (
                                                <Text ellipsis type="secondary">
                                                    {item.expense_id}
                                                </Text>
                                            )}
                                            {item?.failure_reason && (
                                                <Text ellipsis type="secondary">
                                                    {item.failure_reason}
                                                </Text>
                                            )}
                                        </div>
                                    </>
                                )}
                            </Space>
                        </List.Item>
                    )}
                />
            ) : (
                <Text type="secondary">No errors occured during this export.</Text>
            )}
        </Modal>
    )
}

interface ExportedExpense {
    expense_id: string
    external_id: string | null
    external_url: string | null
    failure: boolean
}

interface ExportFailure {
    message: string | null
    expense_id: string | null
    failure_reason: string | null
    export_item_type: string | null
}

const pendingStatus = [
    JobStatus.CREATED,
    JobStatus.EXPORTING_EXPENSES,
    JobStatus.EXPORTING_INVOICES,
    JobStatus.EXPORTING_RECEIPTS,
]

const successStatus = [JobStatus.COMPLETED]

const failedStatus = [JobStatus.FAILED]

export const StatusTag: React.FC<{ status: JobStatus }> = ({ status }) => {
    const tagStatus = useMemo(() => {
        if (pendingStatus.includes(status)) {
            return {
                color: 'processing',
                icon: <SyncOutlined spin />,
            }
        }
        if (successStatus.includes(status)) {
            return {
                color: 'success',
                icon: <CheckCircleOutlined />,
            }
        }
        if (failedStatus.includes(status)) {
            return {
                color: 'error',
                icon: <CloseCircleOutlined />,
            }
        }
    }, [status])

    return (
        <Tag icon={tagStatus?.icon} color={tagStatus?.color}>
            {capitalize(replace(status, '_', ' '))}
        </Tag>
    )
}

export const Employee = ({ employeeId }: { employeeId?: string }) => {
    const { data: employee } = bff.exports.exportHistoryModal.getEmployee.useQuery({
        employeeId: employeeId,
    })
    return (
        <Text>
            {employee?.firstName} {employee?.lastName}
        </Text>
    )
}
