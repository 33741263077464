import { Formik } from 'formik'
import React, { FC, useState } from 'react'
import { Popconfirm, Tooltip } from 'antd'
import { Select, Form } from 'formik-antd'
import { inputWidth } from 'theme/tokens'
import { KycStatus } from 'types/styx'
import { bff } from 'pages/compliance/bff'

interface Props {
    currentVelocity: KycStatus
    onSubmit: (values: { velocity: KycStatus }) => Promise<void>
    styxId: string
    deimosId: string
}

const UpdateVelocityForm: FC<React.PropsWithChildren<Props>> = ({
    currentVelocity,
    onSubmit,
    deimosId,
    styxId,
}) => {
    const [confirmVisible, setConfirmVisible] = useState(false)
    const kycLevels = Object.values(KycStatus)
    const velocityIndex = kycLevels.findIndex((level) => level === currentVelocity)
    const maxVelocity = kycLevels.findIndex((level) => level === KycStatus.FDD)
    const { data: adverseMedia } = bff.checks.getLatestAdverseMediaCheck.useQuery({
        companyId: styxId,
    })
    const { data: sanctions } = bff.checks.getLatestSanction.useQuery({ companyId: styxId })
    const { data: companyManagement } = bff.checks.getCompanyManagement.useQuery({
        companyId: deimosId,
    })

    const options = kycLevels
        .filter(
            (_, index) =>
                index === velocityIndex ||
                (index === velocityIndex + 1 && velocityIndex + 1 <= maxVelocity)
        )
        .map((level) => ({
            label: level,
            value: level,
        }))

    const getHasOutdatedChecks = () => {
        if (currentVelocity !== KycStatus.FDD) {
            const hasOutdatedSanctions = sanctions?.isSanctionsCheckOutdated
            const hasOutdatedAdverseMedia = adverseMedia?.isAdverseMediaCheckOutdated
            const hasOutdatedChecks = companyManagement
                ? companyManagement?.isSisCheckOutdated || companyManagement?.isPepCheckOutdated
                : false

            return hasOutdatedSanctions || hasOutdatedAdverseMedia || hasOutdatedChecks
        } else {
            return false
        }
    }

    return (
        <Formik
            initialValues={{ velocity: currentVelocity }}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {({ resetForm, submitForm, isSubmitting }) => (
                <Form>
                    <Popconfirm
                        title="Are you sure want to update this company's velocity?"
                        open={confirmVisible}
                        onCancel={() => {
                            setConfirmVisible(false)
                            resetForm()
                        }}
                        okButtonProps={{
                            onClick: () => {
                                submitForm()
                                setConfirmVisible(false)
                            },
                            danger: true,
                            loading: isSubmitting,
                            disabled: isSubmitting,
                        }}
                    >
                        <Tooltip
                            title={
                                getHasOutdatedChecks()
                                    ? 'Disabled until all checks are up to date'
                                    : null
                            }
                        >
                            <Select
                                loading={isSubmitting}
                                disabled={isSubmitting || getHasOutdatedChecks()}
                                onChange={() => setConfirmVisible(true)}
                                style={{ width: `${inputWidth.tiny}` }}
                                size="small"
                                name="velocity"
                                options={options}
                                placement="bottomLeft"
                                data-testid="velocity-select"
                            />
                        </Tooltip>
                    </Popconfirm>
                </Form>
            )}
        </Formik>
    )
}
export default UpdateVelocityForm
