import type { EligibilityVAppliedPolicy } from 'bff/moons/generated/narvi-v1'

export function translateOverdraftAppliedPolicy(appliedPolicy: EligibilityVAppliedPolicy) {
    switch (appliedPolicy) {
        case 'OVERDRAFT_AT_ONBOARDING_AUTOMATIC_UNDERWRITING':
            return 'Activation at onboarding'
        case 'OVERDRAFT_HIGHER_LIMIT_UNDERWRITING':
            return 'Higher Limit'
        default:
            return appliedPolicy
    }
}
