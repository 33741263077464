import { useParams } from 'react-router-dom'
import { creditBff } from '../bff'
import { Alert, Divider, Modal, notification, Skeleton, Space, Typography } from 'antd'
import { MouseEventHandler, useState } from 'react'
import type { UnderwritingApplicationFullDto } from 'bff/moons/generated/kale'
import ApplicationApprovalModal from '../components/underwriting/application-approval-modal'
import ApplicationInformationRequestModal from '../components/underwriting/application-information-request-modal'
import {
    getMaxEligibilityApplicationLimit,
    getMinEligibilityApplicationLimit,
} from '../utils/getMinEligibilityApplicationLimit'
import OverdraftApplicationsList from '../components/underwriting/overdraft-applications-list'
const { confirm } = Modal
const { Title } = Typography

export default function CreditUnderwriting() {
    const { id: companyId } = useParams()
    const [applicationToApprove, setApplicationToApprove] =
        useState<UnderwritingApplicationFullDto | null>(null)
    const [applicationForInformation, setApplicationForInformation] =
        useState<UnderwritingApplicationFullDto | null>(null)

    const { mutateAsync: approveApplication } =
        creditBff.approveEligibilityApplication.useMutation()
    const { mutateAsync: rejectApplication } = creditBff.rejectEligibilityApplication.useMutation()
    const { mutate: sendInformationRequest, isLoading: isSendingInfoRequest } =
        creditBff.sendInformationRequestForHigherLimit.useMutation({
            onSuccess: () => {
                setApplicationForInformation(null)
                notification.success({
                    message: 'Information request sent!',
                })
            },
            onError: () =>
                notification.error({
                    message: 'Information request failed!',
                }),
        })

    const { data, isLoading, isError } = creditBff.getUnderwriting.useQuery({ companyId })

    if (isLoading) {
        return <Skeleton active />
    }

    if (isError) {
        return <Alert message="There was an error fetching Underwriting Data." type="error" />
    }

    const handleRejectApplication = async (applicationId: string) => {
        try {
            await rejectApplication({ companyId, applicationId })
            notification.success({
                message: 'Application rejected!',
            })
        } catch {
            notification.error({
                message: 'Application rejection failed!',
            })
        }
    }

    const handleApprovalSubmit = async (values: { approvedLimit: number }) => {
        if (applicationToApprove === null) return null
        try {
            await approveApplication({
                companyId,
                applicationId: applicationToApprove.id,
                approvedLimit: values.approvedLimit,
            })
            notification.success({
                message: 'Application approved!',
            })
        } catch {
            notification.error({
                message: 'Application approval failed!',
            })
        } finally {
            setApplicationToApprove(null)
        }
    }

    const handleInformationRequestSubmit = async (values: { comment: string }) => {
        if (applicationForInformation === null) return null
        confirm({
            centered: true,
            title: 'Are you sure you want to send information request?',
            onOk() {
                sendInformationRequest({
                    companyId,
                    applicationId: applicationForInformation.id,
                    comment: values.comment,
                })
            },
        })
    }

    const showRejectionWarning = (applicationId: string) => {
        confirm({
            title: 'Warning! Limit has already been approved!',
            content: (
                <div>
                    <p>
                        After rejection, you need to manually adjust the customer's eligibility
                        limit to avoid them using the higher limit.
                    </p>
                    <p>
                        Approval email was already sent, so you should consider reaching out to the
                        customer to avoid confusion.
                    </p>
                </div>
            ),
            onOk() {
                handleRejectApplication(applicationId)
            },
        })
    }

    const handleStateChange: (
        action: 'approval' | 'rejection' | 'informationRequest',
        application: UnderwritingApplicationFullDto
    ) => MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement> =
        (action, application) => async (event) => {
            event.stopPropagation()
            switch (action) {
                case 'approval':
                    setApplicationToApprove(application)
                    break
                case 'rejection':
                    confirm({
                        centered: true,
                        title: 'Are you sure you want to reject this application?',
                        onOk() {
                            if (application.currentState === 'APPROVED') {
                                showRejectionWarning(application.id)
                            } else {
                                handleRejectApplication(application.id)
                            }
                        },
                    })
                    break
                case 'informationRequest':
                    setApplicationForInformation(application)
                    break
            }
        }

    return (
        <div>
            <Space direction="vertical" style={{ width: '100%' }} size={'large'}>
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Title level={3}>Eligibility Applications</Title>
                    <OverdraftApplicationsList
                        applications={data.eligibilityApplications}
                        onStateChange={handleStateChange}
                    />
                </Space>
                <Divider />
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Title level={3}>Higher Limit Applications</Title>
                    <OverdraftApplicationsList
                        applications={data.higherLimitApplications}
                        onStateChange={handleStateChange}
                    />
                </Space>
            </Space>
            {applicationToApprove && (
                <ApplicationApprovalModal
                    application={applicationToApprove}
                    minLimit={getMinEligibilityApplicationLimit(
                        applicationToApprove.requestedLimit.currency
                    )}
                    maxLimit={getMaxEligibilityApplicationLimit(
                        applicationToApprove.requestedLimit.currency
                    )}
                    onCancel={() => setApplicationToApprove(null)}
                    onSubmit={handleApprovalSubmit}
                />
            )}
            <ApplicationInformationRequestModal
                application={applicationForInformation}
                onCancel={() => setApplicationForInformation(null)}
                onSubmit={handleInformationRequestSubmit}
                isLoading={isSendingInfoRequest}
            />
        </div>
    )
}
