import { Card } from 'antd'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import PartnerEmployeesContainer from 'components/employees/partner-employees'
import { PageContentLayout } from 'components/layout-containers'
import ProtectedRoute from 'router/protected-route'
import type { Partner } from 'types/partner-portal'
import { PartnerClientsContainer as Clients } from './clients/clients'
import { PartnerProgram } from './partner-program'
import { PartnerReferrals } from './referrals/referrals'
import { PartnerAttributions } from './attributions/attributions'
import { PartnerTeams } from './teams/teams'

const PartnerTabs = ({ partner }: { partner: Partner }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const defaultActiveKey = location.pathname.split('/').reverse()[0] ?? 'overview'

    const tabList = [
        { key: 'clients', tab: 'Clients' },
        { key: 'employees', tab: 'Employees' },
        { key: 'partner-teams', tab: 'Teams' },
        { key: 'partner-program', tab: 'Partner Program' },
        { key: 'partner-referrals', tab: 'Referrals' },
        { key: 'partner-attributions', tab: 'Attributions' },
    ]

    return (
        <PageContentLayout>
            <Card
                tabList={tabList}
                defaultActiveTabKey={defaultActiveKey}
                onTabChange={(tabKey: string) => {
                    navigate(tabKey, { replace: true })
                }}
            >
                <Routes>
                    <Route element={<ProtectedRoute />}>
                        <Route path="*" element={<Clients partner={partner} />} />
                        <Route path="/clients" element={<Clients partner={partner} />} />
                        <Route
                            path="/employees"
                            element={<PartnerEmployeesContainer partner={partner} />}
                        />
                        <Route path="/partner-teams" element={<PartnerTeams />} />
                        <Route path="/partner-program" element={<PartnerProgram />} />
                        <Route path="/partner-referrals" element={<PartnerReferrals />} />
                        <Route path="/partner-attributions" element={<PartnerAttributions />} />
                    </Route>
                </Routes>
            </Card>
        </PageContentLayout>
    )
}

export default PartnerTabs
