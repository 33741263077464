/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import React, { FC, useState } from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { Card, Descriptions, Divider, Empty, Table, Tag } from 'antd'
import { CalendarOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import dayjs from 'packages/dayjs'
import type { SettlementReconciliationOverview } from '@pleo-io/deimos'

import { isFuture, isWeekend } from 'utils/dayjs'
import {
    useSettlementsByDay,
    useSettlementReconciliationSummary,
} from 'services/deimos/settlements'
import formatCurrency from 'utils/format-currency'
import Settlement from './settlement/settlement'
import SettlementReconciliationDashboard from './reconciliation-dashboard'
import { DayjsDatePicker } from 'packages/form/date-picker'

// Thresholds set by finance
// Ping Fred for more details
enum CURRENCY_THRESHOLD {
    DKK = 50,
    SEK = 50,
    NOK = 50,
    GBP = 10,
    EUR = 10,
}
const TODAY = dayjs().format('YYYY-MM-DD')

const isOutsideThreshold = (delta: string, currency: keyof typeof CURRENCY_THRESHOLD) =>
    Math.abs(Number(delta)) > CURRENCY_THRESHOLD[currency]

const renderColumnAmount = (amount: string, { currency }: SettlementReconciliationOverview) =>
    formatCurrency(amount, currency)

const MastercardAdvisements: FC<React.PropsWithChildren<unknown>> = () => {
    const [activeDate, setActiveDate] = useState(TODAY)
    const { data: settlements, mutations } = useSettlementsByDay(activeDate)
    const { data: reconciliationSummary } = useSettlementReconciliationSummary(activeDate)
    return (
        <>
            <PageHeader
                ghost={false}
                title={
                    <span>
                        <CalendarOutlined /> {dayjs(activeDate).format('ll')}
                    </span>
                }
            >
                <Descriptions column={2}>
                    <Descriptions.Item>
                        <DayjsDatePicker
                            disabledDate={(currentDate) =>
                                isFuture(currentDate) || isWeekend(currentDate)
                            }
                            onChange={(_, dateString) => setActiveDate(dateString as string)}
                            value={dayjs(activeDate)}
                            allowClear={false}
                        />
                    </Descriptions.Item>
                    <Descriptions.Item label="Metabase">
                        <a
                            href="https://metabase.pleo.io/question/5952"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Transactions and cardholder fees
                        </a>
                    </Descriptions.Item>
                </Descriptions>
            </PageHeader>
            <Divider style={{ margin: 0 }} />
            <Layout>
                <Card title="Settlement Status - GPS" loading={!settlements}>
                    {settlements?.length ? (
                        <SettlementGrid>
                            {settlements
                                .filter((settlement) => settlement.paymentProvider === 'GPS')
                                .map((settlement) => (
                                    <Settlement
                                        key={settlement.id}
                                        settlement={settlement}
                                        onCancelSettlement={() =>
                                            mutations.cancelSettlement(settlement.id)
                                        }
                                        onMarkAsPaidManually={() =>
                                            mutations.markAsPaidManually(settlement.id)
                                        }
                                        onSetNote={(note: string) =>
                                            mutations.setNote(settlement.id, note)
                                        }
                                    />
                                ))}
                        </SettlementGrid>
                    ) : (
                        <Empty />
                    )}
                </Card>
                <Card title="Settlement Status - ENFUCE" loading={!settlements}>
                    {settlements?.length ? (
                        <SettlementGrid>
                            {settlements
                                .filter((settlement) => settlement.paymentProvider === 'ENFUCE')
                                .map((settlement) => (
                                    <Settlement
                                        key={settlement.id}
                                        settlement={settlement}
                                        onCancelSettlement={() =>
                                            mutations.cancelSettlement(settlement.id)
                                        }
                                        onMarkAsPaidManually={() =>
                                            mutations.markAsPaidManually(settlement.id)
                                        }
                                        onSetNote={(note: string) =>
                                            mutations.setNote(settlement.id, note)
                                        }
                                    />
                                ))}
                        </SettlementGrid>
                    ) : (
                        <Empty />
                    )}
                </Card>
                <Card title="Reconciliation Overview">
                    <Table
                        dataSource={reconciliationSummary}
                        loading={!reconciliationSummary}
                        pagination={false}
                    >
                        <Table.Column title="Settlement Id" dataIndex="settlementId" />
                        <Table.Column title="Currency" dataIndex="currency" />
                        <Table.Column
                            title="Settlement Amount"
                            dataIndex="settlementAmount"
                            render={renderColumnAmount}
                        />
                        <Table.Column
                            title="Transactions Amount"
                            dataIndex="transactionsAmount"
                            render={renderColumnAmount}
                        />
                        <Table.Column
                            title="Fee Collections Amount"
                            dataIndex="feeCollectionAmount"
                            render={renderColumnAmount}
                        />
                        <Table.Column
                            title="Chargeback Cycles Amount"
                            dataIndex="chargebackCyclesAmount"
                            render={renderColumnAmount}
                        />
                        <Table.Column
                            title="Delta"
                            dataIndex="delta"
                            render={(
                                amount: string,
                                { currency, delta }: SettlementReconciliationOverview
                            ) => {
                                const formattedCurrency = formatCurrency(amount, currency)
                                return isOutsideThreshold(delta, currency) ? (
                                    <Tag color="error">{formattedCurrency}</Tag>
                                ) : (
                                    formattedCurrency
                                )
                            }}
                        />
                    </Table>
                    <SettlementReconciliationDashboard settlementDate={activeDate} />
                </Card>
            </Layout>
        </>
    )
}

const SettlementGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: ${spacing.space8};
`

const Layout = styled.div`
    display: grid;
    gap: ${spacing.space24};
    margin: ${spacing.space24};
`

export default MastercardAdvisements
