import { FC, useCallback, useMemo, useState } from 'react'
import { GetBillInvoiceResponse, InvoiceStatus } from '@pleo-io/deimos'
import { Button, Descriptions, message, Modal, Space, Tooltip, Typography } from 'antd'
import dayjs from 'packages/dayjs'
import type { UseBillInvoiceMutations } from 'services/deimos/bill-invoice'
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'

const { Text } = Typography
const { Item } = Descriptions

export const BillInvoiceInformation: FC<{
    invoice?: GetBillInvoiceResponse
    mutations: UseBillInvoiceMutations
    onDelete?: () => void
}> = ({ invoice, onDelete, mutations }) => {
    return (
        <Space direction="vertical">
            {invoice && (
                <BillInvoiceActionBar
                    status={invoice.status}
                    id={invoice.id}
                    onDelete={onDelete}
                    {...mutations}
                />
            )}

            <Descriptions bordered column={{ xxl: 3, xl: 2, md: 1, sm: 1 }} size="small">
                <Item label="Status">{invoice?.status ?? ''}</Item>
                <Item label="Export status">{invoice?.exportStatus ?? ''}</Item>
                <Item label="Amount">{`${invoice?.amount ?? ''} ${invoice?.currency ?? ''}`}</Item>
                <Item label="Invoice number">{invoice?.invoiceNumber ?? ''}</Item>
                <Item label="Expense ID">
                    <Text copyable={{ text: invoice?.expenseId ?? '' }}>
                        {invoice?.expenseId ?? ''}
                    </Text>
                </Item>
                <Item label="Failure reason">{invoice?.failureReason ?? '-'}</Item>
                <Item label="Created at">
                    {invoice?.createdAt ? dayjs(invoice?.createdAt).format('lll') : '-'}
                </Item>
                <Item label="Scheduled at">
                    {invoice?.scheduledAt ? dayjs(invoice?.scheduledAt).format('lll') : '-'}
                </Item>
                <Item label="Paid at">
                    {invoice?.paidAt ? dayjs(invoice?.paidAt).format('lll') : '-'}
                </Item>
                <Item label="Voided at">
                    {invoice?.voidedAt ? dayjs(invoice?.voidedAt).format('lll') : '-'}
                </Item>
            </Descriptions>
        </Space>
    )
}

const BillInvoiceActionBar: FC<
    { status: InvoiceStatus; id: string; onDelete?: () => void } & UseBillInvoiceMutations
> = ({ status, id, onDelete, ...mutations }) => {
    const canFailInternalValidation = ![
        InvoiceStatus.PAID,
        InvoiceStatus.FAILED_INTERNAL_VALIDATION,
    ].includes(status)
    const canFailPayment = [InvoiceStatus.PREPARED, InvoiceStatus.PREPARING].includes(status)
    const canCancelScheduledPayment = [InvoiceStatus.SCHEDULED].includes(status)
    const canDelete = [
        InvoiceStatus.MISSING_INFORMATION,
        InvoiceStatus.PARSING_PENDING_HUMAN_REVIEW,
        InvoiceStatus.PENDING_APPROVAL,
    ].includes(status)
    const [isPendingAction, setIsPendingAction] = useState(false)

    const wrapAction = useCallback(
        (
            action: () => Promise<void>,
            confirmationMessage: string,
            successMessage: string,
            errorMessage: string
        ) =>
            async () => {
                Modal.confirm({
                    centered: true,
                    icon: <ExclamationCircleOutlined />,
                    title: 'Are you sure?',
                    content: confirmationMessage,
                    async onOk() {
                        try {
                            setIsPendingAction(true)
                            await action()
                            message.success(successMessage)
                        } catch (e) {
                            message.error(errorMessage)
                        } finally {
                            setIsPendingAction(false)
                        }
                    },
                })
            },
        []
    )
    const handleFailInternalValidation = useMemo(
        () =>
            wrapAction(
                mutations.failInternalValidation,
                "This will fail the invoice's internal validation.",
                'Invoice status updated to FAILED_INTERNAL_VALIDATION',
                'Could not fail internal validation'
            ),
        [wrapAction, mutations.failInternalValidation]
    )
    const handleCancelPayment = useMemo(
        () =>
            wrapAction(
                canCancelScheduledPayment ? mutations.cancel : mutations.failPayment,
                canCancelScheduledPayment
                    ? "This will cancel the invoice's scheduled payment."
                    : "This will fail the invoice's payment.",
                canCancelScheduledPayment
                    ? 'Invoice payment canceled'
                    : 'Invoice status updated to FAILED_PAYMENT',
                canCancelScheduledPayment
                    ? 'Failed to cancel scheduled payment'
                    : 'Could not fail payment'
            ),
        [wrapAction, canCancelScheduledPayment, mutations.cancel, mutations.failPayment]
    )
    const wrappedDelete = useMemo(
        () =>
            wrapAction(
                mutations.delete,
                'This will delete the invoice permanently.',
                'Invoice deleted',
                'Could not delete invoice'
            ),
        [wrapAction, mutations.delete]
    )
    const handleDelete = useCallback(async () => {
        wrappedDelete()
        onDelete?.()
    }, [wrappedDelete, onDelete])

    return (
        <Space>
            <Tooltip title="Fail the invoice and move it to the archive so it can no longer be paid. Only use in times where a file cannot be paid, for example a fake invoice!">
                <Button
                    disabled={!canFailInternalValidation || isPendingAction}
                    onClick={handleFailInternalValidation}
                >
                    Block Invoice
                </Button>
            </Tooltip>
            <Tooltip title="Cancel the payment, release funds, and return the invoice to the Inbox.">
                <Button
                    disabled={(!canFailPayment && !canCancelScheduledPayment) || isPendingAction}
                    onClick={handleCancelPayment}
                >
                    Cancel payment
                </Button>
            </Tooltip>
            <Tooltip title="Delete the invoice if MISSING_INFORMATION or PENDING_APPROVAL. Once deleted, the invoice is no longer available to the customer anywhere.">
                <Button disabled={!canDelete || isPendingAction} onClick={handleDelete}>
                    Delete invoice
                </Button>
            </Tooltip>
            {isPendingAction && <LoadingOutlined />}
        </Space>
    )
}
