import styled from 'styled-components'
import { spacing } from 'theme/tokens'
import { AdverseMediaScreening } from 'pages/compliance/company/shared/company-kyc-screenings/components/adverse-media-screening'
import { SanctionsScreening } from 'pages/compliance/company/shared/company-kyc-screenings/components/sanctions-screening'
import type { CompanyStakeholderResponse } from 'bff/moons/generated/styx-v1'

interface Props {
    shareholder: CompanyStakeholderResponse
}

export const CompanyShareholderHeader: React.FC<Props> = ({ shareholder }) => {
    const { legalName, ownershipPercentage } = shareholder
    const ownershipPercentageFormatted = ownershipPercentage
        ? ` (ownership: ${ownershipPercentage}%)`
        : ''

    return (
        <PanelHeader>
            <span data-testid="company-name-and-percentage">
                <strong>{legalName}</strong>: {ownershipPercentageFormatted}
            </span>
            <ChecksContainer>
                <AdverseMediaScreening
                    data-testid="adverse-media-checks"
                    companyId={shareholder.id}
                    companyName={legalName || ''}
                    companyTradingName={null}
                    hasFdd={false}
                />
                <SanctionsScreening
                    data-testid="sanctions-checks"
                    companyId={shareholder.id}
                    companyName={legalName || ''}
                    companyTradingName={null}
                    hasFdd={false}
                />
            </ChecksContainer>
        </PanelHeader>
    )
}

const ChecksContainer = styled.div`
    display: flex;
    gap: ${spacing.space8};
`

const PanelHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${spacing.space8};
    max-height: 22px;
`
