import type { FC } from 'react'
import { demoAccountsBff } from '../../bff/demo-accounts-bff'
import { Button, Dropdown, MenuProps, message } from 'antd'
import { BankOutlined, CreditCardOutlined, SettingOutlined } from '@ant-design/icons'
import type { DemoAccount } from '../../bff/index.bff'

const nonEurCurrenciesByCountry: Record<string, string> = {
    UK: 'GBP',
    DK: 'DKK',
    SE: 'SEK',
    NO: 'NOK',
    US: 'USD',
}

interface Props {
    demoAccount: DemoAccount
    selectedEmployees: DemoAccount['employees']
    onPreAction?: () => void
    onPostAction?: () => void
}

export const DemoAccountEmployeeActionsDropdown: FC<Props> = ({
    selectedEmployees,
    demoAccount,
    onPreAction,
    onPostAction,
}) => {
    const { mutateAsync: createBankReimbursement, isLoading: isLoadingBankReimbursement } =
        demoAccountsBff.singleAccount.createBankReimbursement.useMutation()
    const { mutateAsync: deleteEmployee, isLoading: isLoadingDeleteEmployee } =
        demoAccountsBff.singleAccount.deleteEmployee.useMutation()

    const isLoading = isLoadingBankReimbursement || isLoadingDeleteEmployee

    const isMultiple = selectedEmployees.length > 1

    const items: MenuProps['items'] = [
        {
            key: 'create_bank_reimbursement',
            label: isMultiple ? 'Create bank reimbursements' : 'Create bank reimbursement',
            onClick: async () => {
                const amount = Number(prompt('Amount of pocket expense to be reimbursed?'))

                if (!amount) {
                    return
                }

                const merchantName = prompt('Name of the pocked expense merchant?')

                if (!merchantName) {
                    return
                }

                for (const employee of selectedEmployees) {
                    try {
                        onPreAction?.()

                        await createBankReimbursement({
                            companyId: demoAccount.company.id,
                            employeeId: employee.id,
                            value: Number(amount),
                            country: demoAccount.company.country,
                            currency:
                                nonEurCurrenciesByCountry[demoAccount.company.country] ?? 'EUR',
                            pocketExpenseMerchantName: merchantName || 'Excelsior Caffé',
                        })

                        onPostAction?.()

                        message.success(`Reimbursed ${amount} to ${employee.email}`)
                    } catch (e) {
                        message.error('Failed to create bank reimbursement: ' + e)
                    }
                }
            },
            icon: <BankOutlined />,
        },
        {
            key: 'delete_employees',
            label: isMultiple ? 'Delete employees' : 'Delete employee',
            onClick: async () => {
                onPreAction?.()
                for (const employee of selectedEmployees) {
                    try {
                        await deleteEmployee({
                            employeeId: employee.id,
                            companyId: demoAccount.company.id,
                        })
                        message.success(`Deleted employee ${employee.id}`)
                    } catch (e) {
                        message.error('Failed to delete employee: ' + e)
                    }
                }
                onPreAction?.()
            },
            icon: <CreditCardOutlined />,
        },
    ]

    return (
        <Dropdown
            menu={{ items }}
            trigger={['click']}
            disabled={isLoading || selectedEmployees.length === 0}
        >
            <Button
                loading={isLoading}
                type="primary"
                icon={<SettingOutlined />}
                data-testid="single-demo-account-employees-table-actions-dropdown"
            >
                {selectedEmployees.length > 1
                    ? `Bulk actions (${selectedEmployees.length})`
                    : 'Actions'}
            </Button>
        </Dropdown>
    )
}
