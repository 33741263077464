import { Button, Collapse, Descriptions, Empty, Row, Space, Typography } from 'antd'
import type { UnderwritingApplicationFullDto } from 'bff/moons/generated/kale'
import styled from 'styled-components'
import OverdraftApplicationStateTag from './overdraft-application-state-tag'
import { formatMoneyIntl } from 'utils/money'
import OverdraftApplicationStateTrail from './overdraft-application-state-trail'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import type { MouseEventHandler } from 'react'
import OverdraftRequesterDirectorInfo from './overdraft-requester-director-info'
const { Title } = Typography

interface Props {
    applications: UnderwritingApplicationFullDto[]
    onStateChange: (
        action: 'approval' | 'rejection' | 'informationRequest',
        application: UnderwritingApplicationFullDto
    ) => MouseEventHandler<HTMLAnchorElement> & MouseEventHandler<HTMLButtonElement>
}

export default function OverdraftApplicationsList({ applications, onStateChange }: Props) {
    const { id: companyId } = useParams()

    const applicationItems = applications
        .map((application, index) => {
            return {
                testId: `application-header-${index}`,
                key: application.id ?? `${index}`,
                label: (
                    <Row align="middle" justify="space-between">
                        <StyledCardHeader>
                            <Title level={4}>
                                Application on {dayjs(application.createdAt).format('DD-MM-YYYY')}
                            </Title>
                            <OverdraftApplicationStateTag state={application.currentState} />
                        </StyledCardHeader>
                    </Row>
                ),
                children: (
                    <StyledApplicationContainer data-testid={`application-body-${index}`}>
                        <Descriptions column={4} bordered>
                            <Descriptions.Item label="Status">
                                <OverdraftApplicationStateTag state={application.currentState} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Requested limit">
                                {formatMoneyIntl(application.requestedLimit, {
                                    isMinorUnits: true,
                                })}
                            </Descriptions.Item>
                            <Descriptions.Item label="Approved limit">
                                {application.approvedLimit
                                    ? formatMoneyIntl(application.approvedLimit, {
                                          isMinorUnits: true,
                                      })
                                    : '-'}
                            </Descriptions.Item>
                            <Descriptions.Item label="Requested by director?">
                                <OverdraftRequesterDirectorInfo
                                    companyId={companyId}
                                    application={application}
                                />
                            </Descriptions.Item>
                        </Descriptions>
                        <OverdraftApplicationStateTrail
                            application={application}
                            companyId={companyId}
                        />
                    </StyledApplicationContainer>
                ),
                extra: (
                    <Space>
                        <Button
                            disabled={
                                application.currentState === 'APPROVED' ||
                                application.currentState === 'APPROVED_PENDING_ELIGIBILITY'
                            }
                            type="primary"
                            onClick={onStateChange('approval', application)}
                        >
                            Approve
                        </Button>
                        <Button
                            disabled={application.currentState === 'REJECTED'}
                            danger
                            type="primary"
                            onClick={onStateChange('rejection', application)}
                        >
                            Reject
                        </Button>
                        <Button
                            disabled={application.currentState !== 'PENDING'}
                            onClick={onStateChange('informationRequest', application)}
                        >
                            Request information
                        </Button>
                    </Space>
                ),
            }
        })
        .reverse()

    return (
        <div>
            {applicationItems.length > 0 ? (
                <Collapse defaultActiveKey={applicationItems[0].key}>
                    {applicationItems.map((item) => (
                        <Collapse.Panel
                            key={item.key}
                            header={item.label}
                            extra={item.extra}
                            data-testid={item.testId}
                        >
                            {item.children}
                        </Collapse.Panel>
                    ))}
                </Collapse>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </div>
    )
}

const StyledCardHeader = styled.div`
    display: flex;
    column-gap: 2rem;
    align-items: center;
`

const StyledApplicationContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
`
