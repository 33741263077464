import { SettingOutlined } from '@ant-design/icons'
import { Button, MenuProps, Dropdown } from 'antd'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import type { Account } from 'bff/moons/generated/kalyke'
import AccountClosingModal from './account-closing-modal'
import {
    useChangeAccountState,
    useCloseAccount,
    useExpiryDateChange,
    useWriteOffAccount,
} from '../hooks/account-actions-hooks'
import AccountStateChangeModal from './account-state-change-modal'
import ExpiryDateChangeModal from './expiry-date-change-modal'

interface Props {
    account: Account
}

export default function AccountCardActions({ account }: Props) {
    const { id } = useParams()

    const { isClosingModalOpen, setIsClosingModalOpen, closeAccount, isCloseLoading } =
        useCloseAccount(id, account.id)

    const { isWritingOff, writeOffAccount } = useWriteOffAccount(id, account.id)

    const {
        changeAccountState,
        isChangeStateLoading,
        isChangeStateModalOpen,
        setIsChangeStateModalOpen,
    } = useChangeAccountState(account.id)

    const {
        changeExpiryDate,
        isExpiryDateLoading,
        isExpiryDateModalOpen,
        setIsExpiryDateModalOpen,
    } = useExpiryDateChange(account.id)

    const actionItems: MenuProps['items'] = [
        {
            key: 'change-state',
            label: 'Change account state',
            onClick: () => setIsChangeStateModalOpen(true),
        },
        {
            key: 'close-account',
            label: 'Close account',
            onClick: () => setIsClosingModalOpen(true),
        },
        {
            key: 'write-off',
            label: 'Close/Write off account',
            onClick: writeOffAccount,
        },
        {
            key: 'waive-interest',
            label: 'Waive/Write off interest',
            disabled: true,
        },
        {
            key: 'edit-expiry-date',
            label: 'Edit expiry date',
            onClick: () => setIsExpiryDateModalOpen(true),
        },
        {
            key: 'edit-interest-rate',
            label: 'Edit interest rate',
            disabled: true,
        },
    ]

    return (
        <>
            <StyledButtonsContainer>
                <Dropdown menu={{ items: actionItems }} trigger={['click']}>
                    <Button type="primary" icon={<SettingOutlined />} loading={isWritingOff}>
                        Actions
                    </Button>
                </Dropdown>
            </StyledButtonsContainer>
            <AccountClosingModal
                isOpen={isClosingModalOpen}
                onCancel={() => setIsClosingModalOpen(false)}
                onSubmit={closeAccount}
                isLoading={isCloseLoading}
            />
            <AccountStateChangeModal
                isOpen={isChangeStateModalOpen}
                isLoading={isChangeStateLoading}
                onCancel={() => setIsChangeStateModalOpen(false)}
                onSubmit={changeAccountState}
            />
            <ExpiryDateChangeModal
                isOpen={isExpiryDateModalOpen}
                isLoading={isExpiryDateLoading}
                onCancel={() => setIsExpiryDateModalOpen(false)}
                onSubmit={changeExpiryDate}
            />
        </>
    )
}

const StyledButtonsContainer = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`
