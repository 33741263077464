export enum FilterType {
    Select = 'select',
    ComboBox = 'comboBox',
}

export enum FilterName {
    View = 'view',
    Market = 'country',
    Plan = 'plan',
    PriceGeneration = 'generation',
}

export enum FilterLabel {
    View = 'View by',
    Market = 'Market',
    Plan = 'Plan',
    PriceGeneration = 'Price Generation',
}
