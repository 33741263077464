// src/request/index.ts
import Axios2, { AxiosError } from "axios";
import axiosRetry2 from "axios-retry";
import hash from "object-hash";
import qs from "qs";

// src/request/cached-axios.ts
import Axios from "axios";
import axiosRetry from "axios-retry";
import https from "https";
var axiosInstances = /* @__PURE__ */ new Map();
function getCachedAxiosInstance(baseURL, options) {
  let axiosInstance = axiosInstances.get(baseURL);
  if (!axiosInstance) {
    axiosInstance = Axios.create({
      baseURL,
      // The keepAlive option allows us to skip the DNS query and re-use the TCP connection.
      // Since the Axios instances are cached between calls to the same base URL, and even lambda
      // invocations, this should ensure optimal connection re-use
      httpsAgent: new https.Agent({ keepAlive: true })
    });
    if (options == null ? void 0 : options.retries) {
      axiosRetry(axiosInstance, { retries: options.retries });
    }
    axiosInstances.set(baseURL, axiosInstance);
  }
  return axiosInstance;
}

// src/request/errors.ts
var MoonRequestError = class extends Error {
  constructor(opts) {
    const message = opts.message ?? getMessageFromUnknownError(opts.cause, opts.code);
    const cause = opts.cause !== void 0 ? getErrorFromUnknown(opts.cause) : void 0;
    super(message, { cause });
    this.code = opts.code;
    this.data = opts.data;
    this.name = this.constructor.name;
    this.httpStatus = opts.httpStatus;
  }
};
function getMessageFromUnknownError(err, fallback) {
  if (typeof err === "string") {
    return err;
  }
  if (err instanceof Error && typeof err.message === "string") {
    return err.message;
  }
  return fallback || "Unknown error";
}
function getErrorFromUnknown(cause) {
  if (cause instanceof Error) {
    return cause;
  }
  const message = getMessageFromUnknownError(cause);
  return new Error(message);
}

// src/request/index.ts
var DEFAULT_TIMEOUT_MS = 4e3;
var DEFAULT_TIMEOUT_MS_MUTATING_HTTP_METHODS = 6e5;
var MUTATING_HTTP_METHODS = ["put", "post", "patch", "delete"];
var defaultParamsSerializer = {
  serialize: (params) => qs.stringify(params, { arrayFormat: "repeat" })
};
function createMoonRequestCache() {
  return /* @__PURE__ */ new Map();
}
function getDefaultContentType(method) {
  if (method && ["post", "put", "patch"].includes(method.toLowerCase())) {
    return "application/json;charset=UTF-8";
  }
  return void 0;
}
function isTimeoutError(error) {
  return (
    // thrown via the `timeout` param
    error.code === "ECONNABORTED" || // thrown via the `signal` param, using timeoutAbortController
    error.code === "ERR_CANCELED"
  );
}
function getTimeout(clientOptions, axiosConfig) {
  if (clientOptions.timeoutMs !== null && clientOptions.timeoutMs !== void 0) {
    return clientOptions.timeoutMs;
  }
  if (axiosConfig.method && MUTATING_HTTP_METHODS.includes(axiosConfig.method.toLowerCase())) {
    return DEFAULT_TIMEOUT_MS_MUTATING_HTTP_METHODS;
  }
  return DEFAULT_TIMEOUT_MS;
}
function moonRequest(moonClientName, _ignored) {
  return async function request(axiosConfig, clientOptions) {
    var _a, _b, _c;
    const { moonRequestContext: ctx } = clientOptions.ctx;
    const baseURL = ctx.getBaseUrl(moonClientName);
    const method = ((_a = axiosConfig.method) == null ? void 0 : _a.toLowerCase()) ?? "get";
    const urlResource = `${method.toUpperCase()} ${baseURL}${axiosConfig.url ?? ""}`;
    const timeoutMs = getTimeout(clientOptions, axiosConfig);
    const requestStartedAt = (/* @__PURE__ */ new Date()).getTime();
    const requestMetadataPayload = {
      method,
      moon: moonClientName,
      url: baseURL,
      path: axiosConfig.url
    };
    const axiosInstance = getCachedAxiosInstance(baseURL, { retries: 3 });
    try {
      const [cachedRequestPromise, handleRequestCache] = getCachedRequest({
        ctx,
        baseURL,
        axiosConfig
      });
      if (cachedRequestPromise) {
        const response2 = await cachedRequestPromise;
        ctx.logger.debug("Cached moon request", requestMetadataPayload);
        return response2.data;
      }
      const timeoutAbortController = new AbortController();
      setTimeout(() => timeoutAbortController.abort(), timeoutMs + 10);
      const requestPromise = axiosInstance.request({
        data: clientOptions.data,
        ...axiosConfig,
        paramsSerializer: clientOptions.paramsSerializer ?? defaultParamsSerializer,
        timeout: timeoutMs,
        signal: timeoutAbortController.signal,
        headers: {
          Authorization: ctx.authorizationHeader ?? "",
          "Content-Type": ((_b = axiosConfig.headers) == null ? void 0 : _b["Content-Type"]) ?? getDefaultContentType(axiosConfig.method),
          ...axiosConfig.headers
        },
        "axios-retry": {
          onRetry(retryCount, error) {
            ctx.logger.info("Failed moon request, retrying", {
              ...requestMetadataPayload,
              retryCount,
              error: {
                message: error.message,
                stack: error.stack
              },
              code: error.code
            });
          },
          retryCondition: (error) => {
            return !isTimeoutError(error) && axiosRetry2.isNetworkOrIdempotentRequestError(error);
          },
          ...clientOptions.retries === null || clientOptions.retries === void 0 ? {} : { retries: clientOptions.retries }
        }
      });
      handleRequestCache(requestPromise);
      const response = await requestPromise;
      ctx.logger.debug("Moon request successful", {
        ...requestMetadataPayload,
        responseTimeMs: (/* @__PURE__ */ new Date()).getTime() - requestStartedAt
      });
      return response.data;
    } catch (error) {
      if (error instanceof MoonRequestError) {
        throw error;
      }
      if (Axios2.isAxiosError(error) || error instanceof AxiosError) {
        const { message, response, stack } = error;
        const data = response == null ? void 0 : response.data;
        const status = response == null ? void 0 : response.status;
        const responseTimeMs = (/* @__PURE__ */ new Date()).getTime() - requestStartedAt;
        const errorMetadataPayload = {
          ...requestMetadataPayload,
          responseTimeMs,
          status,
          code: error.code,
          error: { message, stack }
          // NOTE: will be parsed by Datadog
        };
        if (status && ((_c = clientOptions.suppressWarnLogForErrorCodes) == null ? void 0 : _c.includes(status))) {
          ctx.logger.info("Moon request failed (ignored)", errorMetadataPayload);
        } else {
          ctx.logger.warn("Moon request failed", errorMetadataPayload);
        }
        const cause = new Error();
        cause.name = `AxiosError - ${error.code || "unknown"}`;
        cause.message = `${message} 
 problematic url: "${urlResource}"`;
        cause.cause = error.cause;
        cause.stack = stack;
        if (isTimeoutError(error)) {
          throw new MoonRequestError({
            cause,
            code: "TIMEOUT",
            // The BFF acts as a gateway to the moon, so we can respond with a gateway-timeout
            // if the request to the moon does not finish within our timeout.
            // If considering a 408 status instead, be aware that Chrome will retry all
            // requests with that response status, see WEB-633 for details.
            httpStatus: 504,
            message: `Request timed out after ${timeoutMs}ms`
          });
        }
        throw new MoonRequestError({
          cause,
          code: "API_ERROR",
          data,
          httpStatus: status,
          message
        });
      } else {
        ctx.logger.error("Moon request failed (non AxiosError)", {
          method,
          url: urlResource,
          error
        });
      }
      throw new MoonRequestError({
        cause: error,
        code: "INTERNAL_ERROR",
        message: "Internal Server Error. No attempts have been made to handle this error."
      });
    }
  };
}
function getCachedRequest({
  baseURL,
  ctx,
  axiosConfig
}) {
  const method = axiosConfig.method ?? "get";
  const requestHash = hash({
    method,
    baseURL,
    path: axiosConfig.url,
    query: axiosConfig.params,
    headers: axiosConfig.headers
  });
  const handleRequestCache = (promiseToCache) => {
    if (method === "get") {
      ctx.__requestCache.set(requestHash, promiseToCache);
    } else {
      ctx.__requestCache.clear();
    }
  };
  const cachedPromise = ctx.__requestCache.get(requestHash) ?? null;
  return [cachedPromise, handleRequestCache];
}
export {
  MoonRequestError,
  createMoonRequestCache,
  getCachedAxiosInstance,
  moonRequest
};
