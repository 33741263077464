import {
    OrderFilters,
    CardOrderStatus,
    CardOrder,
    CardOrderTableData,
    ProcessingStatus,
    DeliveryType,
    DisplayManufacturer,
    Manufacturer,
    Order,
} from 'store/modules/support/card-orders/types'
import { Address, KycProperties } from 'types/deimos-company'
import { ProviderName } from 'types/provider-name'
import { uppercaseFirstLetter } from 'utils/strings'
import getCompanyKycStatus from 'utils/get-company-kyc-status'
import { TrackingLink } from 'types/card-orders'

export const initialFilters: OrderFilters = {
    companyId: '',
    status: [CardOrderStatus.CREATED, CardOrderStatus.PROCESSING],
    kycStatus: [
        KycProperties.SDD,
        KycProperties.FDD,
        KycProperties.EDD,
        KycProperties.HEDD,
        KycProperties.HEDD1,
    ],
    hasWalletLoad: ['YES', 'NO'],
    providerName: [ProviderName.ENFUCE, ProviderName.GPS],
    dateRange: {
        to: '',
        from: '',
    },
}

const isCreated = (cardOrder: CardOrder | CardOrderTableData | null) => {
    return cardOrder?.status === CardOrderStatus.CREATED
}

const isProcessing = (cardOrder: CardOrder | CardOrderTableData | null) => {
    return cardOrder?.status === CardOrderStatus.PROCESSING
}

export const canUpdateStatus = (cardOrder: CardOrder | CardOrderTableData | null) => {
    return !!cardOrder && !isCreated(cardOrder) && !isProcessing(cardOrder)
}

export const hasFailedProcessing = (cardOrder: CardOrder | CardOrderTableData | null) => {
    return (
        !!cardOrder &&
        isProcessing(cardOrder) &&
        cardOrder?.processingStatus === ProcessingStatus.ERROR
    )
}

export const displayManufacturer = (manufacturer?: Manufacturer) =>
    manufacturer ? DisplayManufacturer[manufacturer] : '-'

export const displayDeliveryType = (deliveryType?: DeliveryType) =>
    !deliveryType
        ? '-'
        : deliveryType === DeliveryType.EXPRESS
          ? uppercaseFirstLetter(DeliveryType.EXPRESS)
          : uppercaseFirstLetter(DeliveryType.STANDARD)

export const toTableFormat = (cardOrders?: CardOrder[]): CardOrderTableData[] =>
    !cardOrders
        ? []
        : cardOrders.map(
              ({
                  id,
                  createdAt,
                  updatedAt,
                  amount,
                  company,
                  address,
                  status,
                  providerName,
                  processingStatus,
                  deliveryStatus,
                  trackingId,
                  manufacturer,
                  deliveryType,
                  supplierId,
              }) => ({
                  id,
                  createdAt: createdAt,
                  updatedAt: updatedAt || createdAt,
                  amount,
                  companyId: company?.id || '',
                  companyName: company ? company.name : 'NO COMPANY ASSOCIATED WITH THIS ORDER',
                  companyCountry: company ? company?.address.country : '',
                  kycStatus: company ? getCompanyKycStatus(company) : KycProperties.UNSET,
                  providerName,
                  processingStatus,
                  address,
                  status,
                  deliveryStatus,
                  trackingId,
                  manufacturer,
                  deliveryType,
                  supplierId,
              })
          )

export const groupByAddress = (orders: Order[]) => {
    const grouped: Order[] = []
    for (const order of orders) {
        const ordersWithSameAddress = grouped.filter((elem) =>
            equalAddresses(elem.address, order.address)
        )
        if (ordersWithSameAddress.length === 1) {
            const index = grouped.indexOf(ordersWithSameAddress[0])
            grouped[index].names = order.names
                ? (grouped[index].names ?? []).concat(order.names)
                : grouped[index].names
            grouped[index].cardOwners = grouped[index].cardOwners.concat(order.cardOwners)
        } else {
            grouped.push(order)
        }
    }
    return grouped
}

export const chunk = (arr: string[]) => {
    const chunks = []
    const n = arr.length
    let i = 0

    while (i < n) {
        chunks.push({ column1: arr[i], column2: arr[i + 1], column3: arr[i + 2] })
        i += 3
    }
    return chunks
}

export const addressPreview = (address: Address) => {
    if (!address.addressLine1) {
        return ''
    }
    const region = address.region ? `, ${address.region}` : ''
    const addressLine2 = address.addressLine2 ? `, ${address.addressLine2}` : ''
    const addressLine3 = `${address.locality}, ${address.postalCode}${region}, ${address.country}`
    return `${address.addressLine1}${addressLine2}, ${addressLine3}`
}

export const equalAddresses = (addr1: Address, addr2: Address) =>
    addr1 && addr2 && Object.values(addr1).every((line, i) => line === Object.values(addr2)[i])

export const TrackingLinkResolver: Record<string, TrackingLink> = {
    DE: TrackingLink.AFTERSHIP_DE,
    DK: TrackingLink.AFTERSHIP_DK,
    ES: TrackingLink.AFTERSHIP_ES,
    SE: TrackingLink.AFTERSHIP_SE,
    FR: TrackingLink.AFTERSHIP_FR,
    NO: TrackingLink.AFTERSHIP_NO,
}
