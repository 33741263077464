/**
 * Generated by orval v6.15.0 🍺
 * Do not edit manually.
 * Case Manager API
 * Case Manager OpenAPI definitions
 * OpenAPI spec version: 11.4.1
 */
import { moonRequest as _moonRequest } from '@pleo-io/bff-moon-clients'

const moonRequest = _moonRequest('caseManager')

export type GetCaseParams = {
    /**
     * Include deleted cases
     */
    includeDeleted?: boolean
}

export type GetTotalCasesPerWaitStatusStateItem =
    (typeof GetTotalCasesPerWaitStatusStateItem)[keyof typeof GetTotalCasesPerWaitStatusStateItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTotalCasesPerWaitStatusStateItem = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

export type GetTotalCasesPerWaitStatusParams = {
    /**
     * The entity ID a case is associated with.
     */
    entityId?: string
    /**
     * The assignee ID of a case.
     */
    assigneeId?: string
    /**
     * The assignee ID the case was last assigned to.
     */
    lastAssigneeId?: string
    /**
     * The state of a case should be comprised within this list. Leaving this null or empty will return all states.
     */
    state?: GetTotalCasesPerWaitStatusStateItem[]
    /**
     * The lower bound of the created date range as ISO string.
     */
    created_from?: string
    /**
     * The upper bound of the created date range as ISO string.
     */
    created_to?: string
    /**
     * The lower bound of the due date range as ISO string.
     */
    due_from?: string
    /**
     * The upper bound of the due date range as ISO string.
     */
    due_to?: string
    /**
     * The lower bound of the assigned_at date range as ISO string.
     */
    assigned_at_from?: string
    /**
     * The upper bound of the assigned_at date range as ISO string.
     */
    assigned_at_to?: string
    /**
     * The lower bound of the last state change date range as ISO string.
     */
    last_state_change_at_from?: string
    /**
     * The upper bound of the last state change date range as ISO string.
     */
    last_state_change_at_to?: string
    /**
     * The lower bound of the first assigned date range as ISO string.
     */
    first_assigned_at_from?: string
    /**
     * The upper bound of the first assigned date range as ISO string.
     */
    first_assigned_at_to?: string
    /**
     * Whether to include deleted cases in the search results.
     */
    include_deleted?: boolean
    /**
     * Whether only unassigned cases should be included in the search results.
     */
    unassigned_only?: boolean
    /**
     * Metadata query as a JSON string.
     */
    metadata?: string
    /**
     * Lookback window in days for wallet blocked due on REMINDER_2 cases
     */
    wb_due_threshold?: number
    /**
     * Whether to order the results by custom order.
     */
    custom_order?: boolean
}

export type GetTotalNumberStateItem =
    (typeof GetTotalNumberStateItem)[keyof typeof GetTotalNumberStateItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetTotalNumberStateItem = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

export type GetTotalNumberParams = {
    /**
     * The entity ID a case is associated with.
     */
    entityId?: string
    /**
     * The assignee ID of a case.
     */
    assigneeId?: string
    /**
     * The assignee ID the case was last assigned to.
     */
    lastAssigneeId?: string
    /**
     * The state of a case should be comprised within this list. Leaving this null or empty will return all states.
     */
    state?: GetTotalNumberStateItem[]
    /**
     * The lower bound of the created date range as ISO string.
     */
    created_from?: string
    /**
     * The upper bound of the created date range as ISO string.
     */
    created_to?: string
    /**
     * The lower bound of the due date range as ISO string.
     */
    due_from?: string
    /**
     * The upper bound of the due date range as ISO string.
     */
    due_to?: string
    /**
     * The lower bound of the assigned_at date range as ISO string.
     */
    assigned_at_from?: string
    /**
     * The upper bound of the assigned_at date range as ISO string.
     */
    assigned_at_to?: string
    /**
     * The lower bound of the last state change date range as ISO string.
     */
    last_state_change_at_from?: string
    /**
     * The upper bound of the last state change date range as ISO string.
     */
    last_state_change_at_to?: string
    /**
     * The lower bound of the first assigned date range as ISO string.
     */
    first_assigned_at_from?: string
    /**
     * The upper bound of the first assigned date range as ISO string.
     */
    first_assigned_at_to?: string
    /**
     * Whether to include deleted cases in the search results.
     */
    include_deleted?: boolean
    /**
     * Whether only unassigned cases should be included in the search results.
     */
    unassigned_only?: boolean
    /**
     * Metadata query as a JSON string.
     */
    metadata?: string
    /**
     * Lookback window in days for wallet blocked due on REMINDER_2 cases
     */
    wb_due_threshold?: number
    /**
     * Whether to order the results by custom order.
     */
    custom_order?: boolean
}

export type SearchCasesStateItem = (typeof SearchCasesStateItem)[keyof typeof SearchCasesStateItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SearchCasesStateItem = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

export type SearchCasesParams = {
    /**
     * The entity ID a case is associated with.
     */
    entityId?: string
    /**
     * The assignee ID of a case.
     */
    assigneeId?: string
    /**
     * The assignee ID the case was last assigned to.
     */
    lastAssigneeId?: string
    /**
     * The state of a case should be comprised within this list. Leaving this null or empty will return all states.
     */
    state?: SearchCasesStateItem[]
    /**
     * The lower bound of the created date range as ISO string.
     */
    created_from?: string
    /**
     * The upper bound of the created date range as ISO string.
     */
    created_to?: string
    /**
     * The lower bound of the due date range as ISO string.
     */
    due_from?: string
    /**
     * The upper bound of the due date range as ISO string.
     */
    due_to?: string
    /**
     * The lower bound of the assigned_at date range as ISO string.
     */
    assigned_at_from?: string
    /**
     * The upper bound of the assigned_at date range as ISO string.
     */
    assigned_at_to?: string
    /**
     * The lower bound of the last state change date range as ISO string.
     */
    last_state_change_at_from?: string
    /**
     * The upper bound of the last state change date range as ISO string.
     */
    last_state_change_at_to?: string
    /**
     * The lower bound of the first assigned date range as ISO string.
     */
    first_assigned_at_from?: string
    /**
     * The upper bound of the first assigned date range as ISO string.
     */
    first_assigned_at_to?: string
    /**
     * Whether to include deleted cases in the search results.
     */
    include_deleted?: boolean
    /**
     * Whether only unassigned cases should be included in the search results.
     */
    unassigned_only?: boolean
    /**
     * Metadata query as a JSON string.
     */
    metadata?: string
    /**
     * Lookback window in days for wallet blocked due on REMINDER_2 cases
     */
    wb_due_threshold?: number
    /**
     * Whether to order the results by custom order.
     */
    custom_order?: boolean
    /**
     * Lower bound of the page of data to return (cannot be used together with [after] or [offset])
     */
    before?: string
    /**
     * Upper bound of the page of data to return (cannot be used together with [before] or [offset])
     */
    after?: string
    /**
     * Offset of the page of data to return (cannot be used together with [before] or [after])
     */
    offset?: number
    /**
     * The maximum amount of items to return
     */
    limit?: number
    /**
     * The keys to sort the results by
     */
    sorting_keys?: string[]
    /**
     * The order to sort the results by. Must be the same length as [sortingKeys]; one order per key
     */
    sorting_order?: PageOrder[]
}

export type GetCaseByCompanyParams = {
    /**
     * Include deleted cases
     */
    include_deleted?: boolean
}

export type GetAllCasesParams = {
    /**
     * Include deleted cases
     */
    includeDeleted?: boolean
}

export type RunMigrationParams = {
    /**
     * Page to start processing from
     */
    from?: number
    /**
     * Page to stop processing at
     */
    to?: number
    /**
     * Page size
     */
    size?: number
}

export type TestParams = {
    /**
     * Whether to commit the refresh
     */
    commit?: boolean
}

export type PublishDeletedCases = { [key: string]: any }

export type PublishDeletedCasesCaseType =
    (typeof PublishDeletedCasesCaseType)[keyof typeof PublishDeletedCasesCaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublishDeletedCasesCaseType = {
    ODD: 'ODD',
} as const

export type PublishDeletedCasesParams = {
    /**
     * The case type
     */
    caseType: PublishDeletedCasesCaseType
}

export type DeleteObsoleteCasesParams = {
    /**
     * Whether to commit the deletion
     */
    commit?: boolean
}

export type BackfillOffboardedCompaniesParams = {
    /**
     * Whether to commit the backfill
     */
    commit?: boolean
}

export type BackfillOddCompleteCasesState =
    (typeof BackfillOddCompleteCasesState)[keyof typeof BackfillOddCompleteCasesState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BackfillOddCompleteCasesState = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

export type BackfillOddCompleteCasesParams = {
    /**
     * Whether to commit the update
     */
    commit?: boolean
    /**
     * What state to focus on
     */
    state: BackfillOddCompleteCasesState
}

/**
 * The state of the case
 */
export type UpdateCaseRequestBodyState =
    (typeof UpdateCaseRequestBodyState)[keyof typeof UpdateCaseRequestBodyState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCaseRequestBodyState = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

export interface UpdateCaseRequestBody {
    assignee?: Analyst
    /** Flag to clear the current assignment. */
    clearAssignment?: boolean
    /** Notes added when the case is closed. */
    closedNotes?: string
    /** Labels associated with the case. */
    labels?: string[]
    /** Free notes field. */
    notes?: string
    /** The state of the case */
    state?: UpdateCaseRequestBodyState
}

export type ResultErrorSuppressedItemStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultErrorSuppressedItem = {
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorSuppressedItemStackTraceItem[]
}

export type ResultErrorStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultErrorCauseStackTraceItem = {
    classLoaderName?: string
    className?: string
    fileName?: string
    isNativeMethod?: boolean
    lineNumber?: number
    methodName?: string
    moduleName?: string
    moduleVersion?: string
}

export type ResultErrorCause = {
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorCauseStackTraceItem[]
}

/**
 * Error details if the health check failed
 */
export type ResultError = {
    cause?: ResultErrorCause
    localizedMessage?: string
    message?: string
    stackTrace?: ResultErrorStackTraceItem[]
    suppressed?: ResultErrorSuppressedItem[]
}

/**
 * Additional details about the health check
 */
export type ResultDetails = { [key: string]: { [key: string]: any } }

/**
 * Health check result
 */
export interface Result {
    /** Additional details about the health check */
    details?: ResultDetails
    /** Error details if the health check failed */
    error?: ResultError
    /** Indicates if the service is healthy */
    healthy: boolean
    /** Message providing additional information about the health check */
    message?: string
    /** Timestamp of the health check */
    time: string
}

/**
 * The request body for the record check completed endpoint.
 */
export interface RecordCheckCompletedRequestBody {
    /** The unique identifier of the record check. */
    key: string
}

/**
 * Current state of the case
 */
export type PublicCaseOutputState =
    (typeof PublicCaseOutputState)[keyof typeof PublicCaseOutputState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublicCaseOutputState = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

/**
 * Type of the case
 */
export type PublicCaseOutputCaseType =
    (typeof PublicCaseOutputCaseType)[keyof typeof PublicCaseOutputCaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PublicCaseOutputCaseType = {
    ODD: 'ODD',
} as const

/**
 * Output representation of a case without sensitive information
 */
export interface PublicCaseOutput {
    /** Type of the case */
    caseType: PublicCaseOutputCaseType
    /** Unique identifier of the associated entity */
    entityId: string
    /** Current state of the case */
    state: PublicCaseOutputState
}

export interface PairUUIDInteger {
    first: string
    second: number
}

export type PageOrder = (typeof PageOrder)[keyof typeof PageOrder]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PageOrder = {
    ASC: 'ASC',
    ASC_NULLS_FIRST: 'ASC_NULLS_FIRST',
    ASC_NULLS_LAST: 'ASC_NULLS_LAST',
    DESC: 'DESC',
    DESC_NULLS_FIRST: 'DESC_NULLS_FIRST',
    DESC_NULLS_LAST: 'DESC_NULLS_LAST',
} as const

/**
 * Request body for deleting a case
 */
export interface DeleteCaseRequestBody {
    /** The reason for deleting the case. */
    reason: string
}

export interface DataResponseString {
    data: string
}

export interface DataResponseResult {
    data: Result
}

export interface DataResponsePublicCaseOutput {
    data: PublicCaseOutput
}

export type DataResponseMapUUIDUUIDData = { [key: string]: string }

export interface DataResponseMapUUIDUUID {
    data: DataResponseMapUUIDUUIDData
}

export type DataResponseMapUUIDStringData = { [key: string]: string }

export interface DataResponseMapUUIDString {
    data: DataResponseMapUUIDStringData
}

export type DataResponseMapStringIntegerData = { [key: string]: number }

export interface DataResponseMapStringInteger {
    data: DataResponseMapStringIntegerData
}

export type DataResponseMapODDTrackerEntryCaseData = { [key: string]: Case }

export interface DataResponseMapODDTrackerEntryCase {
    data: DataResponseMapODDTrackerEntryCaseData
}

export type DataResponseMapCaseWaitStatusLongData = { [key: string]: number }

export interface DataResponseMapCaseWaitStatusLong {
    data: DataResponseMapCaseWaitStatusLongData
}

export type DataResponseMapCaseStateLongData = { [key: string]: number }

export interface DataResponseMapCaseStateLong {
    data: DataResponseMapCaseStateLongData
}

export type DataResponseMapCaseStateIntegerData = { [key: string]: number }

export interface DataResponseMapCaseStateInteger {
    data: DataResponseMapCaseStateIntegerData
}

export type DataResponseMapCaseCustomActionIdCaseCustomActionData = {
    [key: string]:
        | 'SCHEDULE_REQUEST_INFO_EMAILS'
        | 'SEND_REMINDER_1_EMAIL'
        | 'SEND_REMINDER_2_EMAIL'
        | 'SEND_WALLET_BLOCKED_EMAIL'
        | 'DELAY_EMAIL_SCHEDULE'
        | 'EXTEND_DEADLINE'
}

export interface DataResponseMapCaseCustomActionIdCaseCustomAction {
    data: DataResponseMapCaseCustomActionIdCaseCustomActionData
}

export interface DataResponseListPairUUIDInteger {
    data: PairUUIDInteger[]
}

export interface DataResponseListCustomActionOutput {
    data: CustomActionOutput[]
}

export interface DataResponseListCaseOutput {
    data: CaseOutput[]
}

export interface DataResponseInteger {
    data: number
}

export interface DataResponseCaseOutput {
    data: CaseOutput
}

export interface DataResponseBoolean {
    data: boolean
}

/**
 * Case states allowed to perform the action
 */
export type CustomActionOutputStatesAllowedItem =
    (typeof CustomActionOutputStatesAllowedItem)[keyof typeof CustomActionOutputStatesAllowedItem]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomActionOutputStatesAllowedItem = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

/**
 * Unique identifier of the action
 */
export type CustomActionOutputActionId =
    (typeof CustomActionOutputActionId)[keyof typeof CustomActionOutputActionId]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CustomActionOutputActionId = {
    SCHEDULE_REQUEST_INFO_EMAILS: 'SCHEDULE_REQUEST_INFO_EMAILS',
    SEND_REMINDER_1_EMAIL: 'SEND_REMINDER_1_EMAIL',
    SEND_REMINDER_2_EMAIL: 'SEND_REMINDER_2_EMAIL',
    SEND_WALLET_BLOCKED_EMAIL: 'SEND_WALLET_BLOCKED_EMAIL',
    DELAY_EMAIL_SCHEDULE: 'DELAY_EMAIL_SCHEDULE',
    EXTEND_WALLET_BLOCKED_DEADLINE: 'EXTEND_WALLET_BLOCKED_DEADLINE',
} as const

/**
 * Output representation of a custom action
 */
export interface CustomActionOutput {
    /** Unique identifier of the action */
    actionId: CustomActionOutputActionId
    /** Name of the action */
    actionName: string
    /** Whether the action is available for the case. For client-side validation */
    isAllowed: boolean
    /** If case requires company admins to perform the action */
    requiresCompanyAdmins: boolean
    /** Case states allowed to perform the action */
    statesAllowed: CustomActionOutputStatesAllowedItem[]
}

export type CursorPageCurrentRequestInfoParameters = { [key: string]: string[] }

export interface CursorPageCurrentRequestInfo {
    after?: string
    before?: string
    limit?: number
    offset?: number
    parameters: CursorPageCurrentRequestInfoParameters
    sortingKeys?: string[]
    sortingOrder?: PageOrder[]
}

export interface CursorPageInfo {
    currentRequestPagination: CursorPageCurrentRequestInfo
    endCursor?: string
    hasNextPage: boolean
    hasPreviousPage: boolean
    startCursor?: string
    total?: number
}

/**
 * List of entities to create cases for
 */
export interface CreateEntityRequestBody {
    /** Due date override for the case */
    dueDate?: string
    /** Unique identifier of the entity */
    entityId: string
}

/**
 * Request body for creating a new case
 */
export interface CreateCaseRequestBody {
    /** Creation Notes */
    creationNotes?: string
    /** List of entities to create cases for */
    entities: CreateEntityRequestBody[]
}

/**
 * Output representation of a completed check
 */
export interface CompletedCheckOutput {
    /** Unique identifier of the associated case */
    caseId: string
    createdBy: AssignedAnalyst
    deletedBy?: AssignedAnalyst
    /** Unique key for the completed check */
    key: string
}

export type CompletedCheckKey = (typeof CompletedCheckKey)[keyof typeof CompletedCheckKey]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CompletedCheckKey = {
    COMPANY_STRUCTURE_REFRESHED: 'COMPANY_STRUCTURE_REFRESHED',
    PROOF_OF_ADDRESS_COLLECTED: 'PROOF_OF_ADDRESS_COLLECTED',
    OWNERSHIP_STRUCTURE_REVIEWED: 'OWNERSHIP_STRUCTURE_REVIEWED',
    UBOS_VERIFIED: 'UBOS_VERIFIED',
    EXECUTIVE_DIRECTORS_VERIFIED: 'EXECUTIVE_DIRECTORS_VERIFIED',
    PEP_SCREENED: 'PEP_SCREENED',
    SANCTIONS_SCREENED: 'SANCTIONS_SCREENED',
    ADVERSE_MEDIA_SCREENED: 'ADVERSE_MEDIA_SCREENED',
    RISK_ASSESSMENT_REVIEWED: 'RISK_ASSESSMENT_REVIEWED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
} as const

export interface CompletedCheck {
    caseId: string
    createdBy: AssignedAnalyst
    deletedBy?: AssignedAnalyst
    key: CompletedCheckKey
}

/**
 * Trigger type of the case
 */
export type CaseOutputTriggerType =
    (typeof CaseOutputTriggerType)[keyof typeof CaseOutputTriggerType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseOutputTriggerType = {
    TIME_TRIGGERED: 'TIME_TRIGGERED',
    EVENT_TRIGGERED: 'EVENT_TRIGGERED',
} as const

/**
 * Current state of the case
 */
export type CaseOutputState = (typeof CaseOutputState)[keyof typeof CaseOutputState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseOutputState = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

/**
 * Metadata associated with the case
 */
export type CaseOutputMetadata = { [key: string]: string }

/**
 * Type of the case
 */
export type CaseOutputCaseType = (typeof CaseOutputCaseType)[keyof typeof CaseOutputCaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseOutputCaseType = {
    ODD: 'ODD',
} as const

/**
 * Output representation of a case
 */
export interface CaseOutput {
    assignedTo?: AssignedAnalyst
    /** Type of the case */
    caseType: CaseOutputCaseType
    /** Notes added when the case is closed */
    closedNotes?: string
    /** List of completed checks for the case */
    completedChecks: CompletedCheckOutput[]
    createdBy: AssignedAnalyst
    deletedBy?: AssignedAnalyst
    /** Reason for deleting the case, if applicable */
    deletedReason?: string
    /** Due date of the case */
    dueDate: string
    /** Unique identifier of the associated entity */
    entityId: string
    firstAssignedTo?: AssignedAnalyst
    /** Unique identifier of the case */
    id: string
    /** Labels associated with the case */
    labels?: string[]
    lastAssignedTo?: AssignedAnalyst
    /** When the case state was last changed */
    lastStateChangeAt: string
    /** Metadata associated with the case */
    metadata: CaseOutputMetadata
    /** Free notes attached to the case */
    notes?: string
    /** Current state of the case */
    state: CaseOutputState
    /** Timeline of case audits */
    timeline: CaseAudit[]
    /** Trigger type of the case */
    triggerType: CaseOutputTriggerType
    updatedBy: AssignedAnalyst
}

export interface CursorPaginatedResponseCaseOutput {
    data: CaseOutput[]
    pagination: CursorPageInfo
}

export type CaseMetadataValues = { [key: string]: string }

export type CaseMetadataSearchInputs = { [key: string]: string }

export interface CaseMetadata {
    searchInputs: CaseMetadataSearchInputs
    values: CaseMetadataValues
}

export type CaseAuditEventType = (typeof CaseAuditEventType)[keyof typeof CaseAuditEventType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseAuditEventType = {
    NEW_CASE: 'NEW_CASE',
    UPDATED_ASSIGNEE: 'UPDATED_ASSIGNEE',
    UPDATED_LABELS: 'UPDATED_LABELS',
    UPDATED_NOTES: 'UPDATED_NOTES',
    UPDATED_CLOSED_NOTES: 'UPDATED_CLOSED_NOTES',
    UPDATED_STATE_TO_IN_PROGRESS: 'UPDATED_STATE_TO_IN_PROGRESS',
    UPDATED_STATE_TO_WAITING_FOR_CUSTOMER: 'UPDATED_STATE_TO_WAITING_FOR_CUSTOMER',
    UPDATED_STATE_TO_INFORMATION_RECEIVED: 'UPDATED_STATE_TO_INFORMATION_RECEIVED',
    UPDATED_STATE_TO_ODD_COMPLETED: 'UPDATED_STATE_TO_ODD_COMPLETED',
    UPDATED_STATE_TO_WALLET_BLOCKED: 'UPDATED_STATE_TO_WALLET_BLOCKED',
    UPDATED_STATE_TO_OFFBOARDING_INITIATED: 'UPDATED_STATE_TO_OFFBOARDING_INITIATED',
    UPDATED_STATE_TO_OFFBOARDING_COMPLETED: 'UPDATED_STATE_TO_OFFBOARDING_COMPLETED',
    COMMUNICATION_ERROR_REQUEST_INFORMATION: 'COMMUNICATION_ERROR_REQUEST_INFORMATION',
    COMMUNICATION_SCHEDULE_REQUEST_INFORMATION: 'COMMUNICATION_SCHEDULE_REQUEST_INFORMATION',
    COMMUNICATION_CANCEL_SCHEDULE_REQUEST_INFORMATION:
        'COMMUNICATION_CANCEL_SCHEDULE_REQUEST_INFORMATION',
    COMMUNICATION_ERROR_REMINDER_1: 'COMMUNICATION_ERROR_REMINDER_1',
    COMMUNICATION_REQUEST_INFORMATION_REMINDER_1: 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_1',
    COMMUNICATION_ERROR_REMINDER_2: 'COMMUNICATION_ERROR_REMINDER_2',
    COMMUNICATION_REQUEST_INFORMATION_REMINDER_2: 'COMMUNICATION_REQUEST_INFORMATION_REMINDER_2',
    COMMUNICATION_ERROR_WALLET_BLOCKED: 'COMMUNICATION_ERROR_WALLET_BLOCKED',
    COMMUNICATION_WALLET_BLOCKED: 'COMMUNICATION_WALLET_BLOCKED',
    COMMUNICATION_DELAY_EMAIL_SCHEDULE: 'COMMUNICATION_DELAY_EMAIL_SCHEDULE',
    COMMUNICATION_CANCEL_WALLET_BLOCKED: 'COMMUNICATION_CANCEL_WALLET_BLOCKED',
    COMMUNICATION_ERROR_ODD_COMPLETED: 'COMMUNICATION_ERROR_ODD_COMPLETED',
    COMMUNICATION_ODD_COMPLETED: 'COMMUNICATION_ODD_COMPLETED',
    COMPANY_SPEND_BLOCKED_ERROR: 'COMPANY_SPEND_BLOCKED_ERROR',
    COMPANY_SPEND_BLOCKED: 'COMPANY_SPEND_BLOCKED',
    CHECK_COMPLETED: 'CHECK_COMPLETED',
    CHECK_DELETED: 'CHECK_DELETED',
    CASE_SOFT_DELETED: 'CASE_SOFT_DELETED',
    NEW_DOCUMENT_UPLOADED: 'NEW_DOCUMENT_UPLOADED',
    EXTERNAL_OFFBOARDING_DETECTED: 'EXTERNAL_OFFBOARDING_DETECTED',
    NEW_RISK_SCORE_DETECTED: 'NEW_RISK_SCORE_DETECTED',
    EXTEND_WALLET_BLOCKED_DEADLINE: 'EXTEND_WALLET_BLOCKED_DEADLINE',
} as const

/**
 * Timeline of case audits
 */
export interface CaseAudit {
    by: AssignedAnalyst
    caseId: string
    description: string
    eventType: CaseAuditEventType
    id: string
}

export type CaseTriggerType = (typeof CaseTriggerType)[keyof typeof CaseTriggerType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseTriggerType = {
    TIME_TRIGGERED: 'TIME_TRIGGERED',
    EVENT_TRIGGERED: 'EVENT_TRIGGERED',
} as const

export type CaseState = (typeof CaseState)[keyof typeof CaseState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseState = {
    NEW: 'NEW',
    IN_PROGRESS: 'IN_PROGRESS',
    WAITING_FOR_CUSTOMER: 'WAITING_FOR_CUSTOMER',
    INFORMATION_RECEIVED: 'INFORMATION_RECEIVED',
    WALLET_BLOCKED: 'WALLET_BLOCKED',
    OFFBOARDING_INITIATED: 'OFFBOARDING_INITIATED',
    OFFBOARDING_COMPLETED: 'OFFBOARDING_COMPLETED',
    ODD_COMPLETED: 'ODD_COMPLETED',
    ESCALATED_TO_NEXT_TIER: 'ESCALATED_TO_NEXT_TIER',
    ESCALATED_REVIEW_IN_PROGRESS: 'ESCALATED_REVIEW_IN_PROGRESS',
    READY_FOR_REVIEW: 'READY_FOR_REVIEW',
    REVIEW_IN_PROGRESS: 'REVIEW_IN_PROGRESS',
    CASE_BLOCKED: 'CASE_BLOCKED',
} as const

export type CaseCaseType = (typeof CaseCaseType)[keyof typeof CaseCaseType]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseCaseType = {
    ODD: 'ODD',
} as const

/**
 * Analyst assigned to a case
 */
export interface AssignedAnalyst {
    /** Date and time of the analyst's assignment to the case */
    at: string
    /** Email of the analyst */
    email?: string
    /** First name of the analyst */
    firstName: string
    /** Unique identifier of the analyst */
    id: string
    /** Last name of the analyst */
    lastName: string
}

export interface Case {
    assignedTo?: AssignedAnalyst
    caseType: CaseCaseType
    closedNotes?: string
    completedChecks?: CompletedCheck[]
    createdBy: AssignedAnalyst
    deletedBy?: AssignedAnalyst
    deletedReason?: string
    dueDate: string
    entityId: string
    firstAssignedTo?: AssignedAnalyst
    id: string
    labels?: string[]
    lastAssignedTo?: AssignedAnalyst
    lastStateChangeAt: string
    metadata?: CaseMetadata
    notes?: string
    state: CaseState
    timeline?: CaseAudit[]
    triggerType: CaseTriggerType
    updatedBy: AssignedAnalyst
}

/**
 * Analyst assigned to a case
 */
export interface Analyst {
    /** Email of the analyst */
    email?: string
    /** First name of the analyst */
    firstName: string
    /** Unique identifier of the analyst */
    id: string
    /** Last name of the analyst */
    lastName: string
}

// eslint-disable-next-line
type SecondParameter<T extends (...args: any) => any> = T extends (
    config: any,
    args: infer P
) => any
    ? P
    : never

/**
 * Returns the readiness health of the service
 */
export const checkReadiness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health`, method: 'get' }, options)
}

/**
 * Returns the liveness health of the service
 */
export const checkLiveness = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseResult>({ url: `/health/liveness`, method: 'get' }, options)
}

/**
 * Backfill ODD complete cases to Kafka
 * @summary Backfill ODD complete cases
 */
export const backfillOddCompleteCases = (
    params: BackfillOddCompleteCasesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapUUIDString>(
        { url: `/v1/admin/backfill-odd-complete-cases`, method: 'put', params },
        options
    )
}

/**
 * Backfill cases to Kafka for the given case type
 * @summary Backfill cases to Kafka
 */
export const backfillOffboardedCompanies = (
    params: BackfillOffboardedCompaniesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        { url: `/v1/admin/backfill-offboarded-companies`, method: 'post', params },
        options
    )
}

export const getNewCases = (options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>(
        { url: `/v1/admin/clear-irrelevant-cases`, method: 'delete' },
        options
    )
}

/**
 * Consolidate wait statuses for cases of the given type
 * @summary Consolidate wait statuses
 */
export const consolidateWaitStatuses = (
    caseType: 'ODD',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseBoolean>(
        { url: `/v1/admin/consolidate-wait-statuses/${caseType}`, method: 'put' },
        options
    )
}

export const deleteObsoleteCases = (
    params: DeleteObsoleteCasesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapCaseStateInteger>(
        { url: `/v1/admin/delete-obsolete-cases`, method: 'delete', params },
        options
    )
}

/**
 * Redrive DLQ messages
 * @summary Redrive DLQ messages
 */
export const redriveDlq = (topic: string, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<unknown>({ url: `/v1/admin/dlq/redrive/${topic}`, method: 'put' }, options)
}

/**
 * Backfill cases to Kafka for the given case type
 * @summary Backfill cases to Kafka
 */
export const backfillCases = (caseType: 'ODD', options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<void>(
        { url: `/v1/admin/kafka-backfill-cases/${caseType}`, method: 'post' },
        options
    )
}

/**
 * Publish deleted cases to Kafka for the given case type
 * @summary Publish deleted cases to Kafka
 */
export const publishDeletedCases = (
    params: PublishDeletedCasesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<PublishDeletedCases>(
        { url: `/v1/admin/publish-deleted-cases`, method: 'get', params },
        options
    )
}

/**
 * Refresh waiting for customer cases
 * @summary Refresh waiting for customer cases
 */
export const test = (params: TestParams, options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseListPairUUIDInteger>(
        { url: `/v1/admin/refresh-waiting-for-customer`, method: 'put', params },
        options
    )
}

/**
 * Fetch and process missing KYC information from Styx for cases of the given type
 * @summary Reprocess missing KYC information from Styx
 */
export const reprocessMissingKycInfo = (
    caseType: 'ODD',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapStringInteger>(
        { url: `/v1/admin/reprocess-missing-kyc-info/${caseType}`, method: 'post' },
        options
    )
}

/**
 * Run migration from LOW/MID tracker
 * @summary Run migration from LOW/MID tracker
 */
export const runMigration = (
    params: RunMigrationParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapODDTrackerEntryCase>(
        { url: `/v1/admin/run-migration`, method: 'put', params },
        options
    )
}

/**
 * Fetch all cases per case type (only ODD at the moment)
 * @summary Fetch all existing cases per case type
 */
export const getAllCases = (
    caseType: 'ODD',
    params: GetAllCasesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListCaseOutput>(
        { url: `/v1/cases/${caseType}`, method: 'get', params },
        options
    )
}

/**
 * Create a new cases for the given case type, automatically resolving metadata
 * @summary Create a new cases
 */
export const createCases = (
    caseType: 'ODD',
    createCaseRequestBody: CreateCaseRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapUUIDUUID>(
        {
            url: `/v1/cases/${caseType}`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: createCaseRequestBody,
        },
        options
    )
}

/**
 * Fetch case by company ID
 * @summary Fetch case by company ID
 */
export const getCaseByCompany = (
    caseType: 'ODD',
    companyId: string,
    params: GetCaseByCompanyParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponsePublicCaseOutput>(
        { url: `/v1/cases/${caseType}/company/${companyId}`, method: 'get', params },
        options
    )
}

/**
 * Retrieves a list of actions an analyst can take for a given case type on a given case.
 * @summary Retrieves a list of actions an analyst can take for a given case type on a given case.
 */
export const getCustomActions = (caseType: 'ODD', options: SecondParameter<typeof moonRequest>) => {
    return moonRequest<DataResponseMapCaseCustomActionIdCaseCustomAction>(
        { url: `/v1/cases/${caseType}/custom-actions`, method: 'get' },
        options
    )
}

/**
 * Search for cases per case type and other criteria
 * @summary Search for existing cases per case type
 */
export const searchCases = (
    caseType: 'ODD',
    params: SearchCasesParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<CursorPaginatedResponseCaseOutput>(
        { url: `/v1/cases/${caseType}/search`, method: 'get', params },
        options
    )
}

/**
 * Calculate the total number of existing cases per case type
 * @summary Calculate the total number of existing cases per case type
 */
export const getTotalNumber = (
    caseType: 'ODD',
    params: GetTotalNumberParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapCaseStateLong>(
        { url: `/v1/cases/${caseType}/total`, method: 'get', params },
        options
    )
}

/**
 * Calculate the total number of existing cases in WAITING_FOR_CUSTOMER per case wait status.This endpoint will ignore the parameter `state` and only consider cases in WAITING_FOR_CUSTOMER
 * @summary Calculate the total number of existing cases in WAITING_FOR_CUSTOMER per case wait status
 */
export const getTotalCasesPerWaitStatus = (
    caseType: 'ODD',
    params: GetTotalCasesPerWaitStatusParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseMapCaseWaitStatusLong>(
        { url: `/v1/cases/${caseType}/total-wait-statuses`, method: 'get', params },
        options
    )
}

/**
 * Delete a case
 * @summary Delete a case
 */
export const deleteCase = (
    caseType: 'ODD',
    caseId: string,
    deleteCaseRequestBody: DeleteCaseRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v1/cases/${caseType}/${caseId}`,
            method: 'delete',
            headers: { 'Content-Type': '*/*' },
            data: deleteCaseRequestBody,
        },
        options
    )
}

/**
 * Fetch a case by its ID
 * @summary Fetch a case by ID
 */
export const getCase = (
    caseType: 'ODD',
    caseId: string,
    params: GetCaseParams,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseCaseOutput>(
        { url: `/v1/cases/${caseType}/${caseId}`, method: 'get', params },
        options
    )
}

/**
 * Update a case
 * @summary Update a case
 */
export const updateCase = (
    caseType: 'ODD',
    caseId: string,
    updateCaseRequestBody: UpdateCaseRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v1/cases/${caseType}/${caseId}`,
            method: 'put',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: updateCaseRequestBody,
        },
        options
    )
}

/**
 * Assign a case to the current user
 * @summary Assign a case to the current user
 */
export const assignCaseToMe = (
    caseType: 'ODD',
    caseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        { url: `/v1/cases/${caseType}/${caseId}/assign-to-me`, method: 'put' },
        options
    )
}

/**
 * Records a check as completed on a case
 * @summary Records a check as completed on a case
 */
export const recordCheckCompleted = (
    caseType: 'ODD',
    caseId: string,
    recordCheckCompletedRequestBody: RecordCheckCompletedRequestBody,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        {
            url: `/v1/cases/${caseType}/${caseId}/checks`,
            method: 'post',
            headers: { 'Content-Type': 'application/json;charset=UTF-8' },
            data: recordCheckCompletedRequestBody,
        },
        options
    )
}

/**
 * Removes a completed check from a case
 * @summary Removes a completed check from a case
 */
export const removeCompletedCheck = (
    caseType: 'ODD',
    caseId: string,
    checkKey: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<void>(
        { url: `/v1/cases/${caseType}/${caseId}/checks/${checkKey}`, method: 'delete' },
        options
    )
}

/**
 * Retrieves a list of actions an analyst can take for a given case type on a given case.
 * @summary Retrieves a list of actions an analyst can take on a given case.
 */
export const getCustomActionsForCase = (
    caseType: 'ODD',
    caseId: string,
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseListCustomActionOutput>(
        { url: `/v1/cases/${caseType}/${caseId}/custom-actions`, method: 'get' },
        options
    )
}

/**
 * Performs a custom action on a case
 * @summary Performs a custom action on a case
 */
export const performCustomAction = (
    caseType: 'ODD',
    caseId: string,
    actionId:
        | 'SCHEDULE_REQUEST_INFO_EMAILS'
        | 'SEND_REMINDER_1_EMAIL'
        | 'SEND_REMINDER_2_EMAIL'
        | 'SEND_WALLET_BLOCKED_EMAIL'
        | 'DELAY_EMAIL_SCHEDULE'
        | 'EXTEND_WALLET_BLOCKED_DEADLINE',
    options: SecondParameter<typeof moonRequest>
) => {
    return moonRequest<DataResponseString>(
        { url: `/v1/cases/${caseType}/${caseId}/custom-actions/${actionId}`, method: 'post' },
        options
    )
}

export type CheckReadinessResult = NonNullable<Awaited<ReturnType<typeof checkReadiness>>>
export type CheckLivenessResult = NonNullable<Awaited<ReturnType<typeof checkLiveness>>>
export type BackfillOddCompleteCasesResult = NonNullable<
    Awaited<ReturnType<typeof backfillOddCompleteCases>>
>
export type BackfillOffboardedCompaniesResult = NonNullable<
    Awaited<ReturnType<typeof backfillOffboardedCompanies>>
>
export type GetNewCasesResult = NonNullable<Awaited<ReturnType<typeof getNewCases>>>
export type ConsolidateWaitStatusesResult = NonNullable<
    Awaited<ReturnType<typeof consolidateWaitStatuses>>
>
export type DeleteObsoleteCasesResult = NonNullable<Awaited<ReturnType<typeof deleteObsoleteCases>>>
export type RedriveDlqResult = NonNullable<Awaited<ReturnType<typeof redriveDlq>>>
export type BackfillCasesResult = NonNullable<Awaited<ReturnType<typeof backfillCases>>>
export type PublishDeletedCasesResult = NonNullable<Awaited<ReturnType<typeof publishDeletedCases>>>
export type TestResult = NonNullable<Awaited<ReturnType<typeof test>>>
export type ReprocessMissingKycInfoResult = NonNullable<
    Awaited<ReturnType<typeof reprocessMissingKycInfo>>
>
export type RunMigrationResult = NonNullable<Awaited<ReturnType<typeof runMigration>>>
export type GetAllCasesResult = NonNullable<Awaited<ReturnType<typeof getAllCases>>>
export type CreateCasesResult = NonNullable<Awaited<ReturnType<typeof createCases>>>
export type GetCaseByCompanyResult = NonNullable<Awaited<ReturnType<typeof getCaseByCompany>>>
export type GetCustomActionsResult = NonNullable<Awaited<ReturnType<typeof getCustomActions>>>
export type SearchCasesResult = NonNullable<Awaited<ReturnType<typeof searchCases>>>
export type GetTotalNumberResult = NonNullable<Awaited<ReturnType<typeof getTotalNumber>>>
export type GetTotalCasesPerWaitStatusResult = NonNullable<
    Awaited<ReturnType<typeof getTotalCasesPerWaitStatus>>
>
export type DeleteCaseResult = NonNullable<Awaited<ReturnType<typeof deleteCase>>>
export type GetCaseResult = NonNullable<Awaited<ReturnType<typeof getCase>>>
export type UpdateCaseResult = NonNullable<Awaited<ReturnType<typeof updateCase>>>
export type AssignCaseToMeResult = NonNullable<Awaited<ReturnType<typeof assignCaseToMe>>>
export type RecordCheckCompletedResult = NonNullable<
    Awaited<ReturnType<typeof recordCheckCompleted>>
>
export type RemoveCompletedCheckResult = NonNullable<
    Awaited<ReturnType<typeof removeCompletedCheck>>
>
export type GetCustomActionsForCaseResult = NonNullable<
    Awaited<ReturnType<typeof getCustomActionsForCase>>
>
export type PerformCustomActionResult = NonNullable<Awaited<ReturnType<typeof performCustomAction>>>
