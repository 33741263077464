import { useCallback, useEffect, useMemo, useState } from 'react'
import Header from './header'
import { ViewType } from './enums'
import { getPlansInputFromQuery, isFilterReady, joinIfArray } from './utils'
import type { GetPlansForMarketsRequest } from './types'
import Table from './table'
import { Container } from './entitlement-dashboard.styles'
import { Modal } from 'antd'
import { useDebounce } from '../../../utils/use-debounce'
import { useSearchParams } from 'react-router-dom'
import { bff } from './bff'

const { error } = Modal

const EntitlementDashboard = () => {
    const [isEditing, setIsEditing] = useState<boolean>(false)
    const [filter, setFilter] = useState<GetPlansForMarketsRequest>({
        country: [],
        plan: [],
        generation: [],
    })
    const debouncedFilter = useDebounce(filter, 1000)

    const getPlansParams = useMemo(() => getPlansInputFromQuery(debouncedFilter), [debouncedFilter])
    const { data: plans, remove: resetPlans } = bff.plans.getPlans.useQuery(getPlansParams, {
        enabled: isFilterReady(getPlansParams),
    })
    const [searchParams, setSearchParams] = useSearchParams()

    // set initial view on mount
    useEffect(() => {
        if (!searchParams.get('view')) {
            searchParams.set('view', ViewType.Market)
            setSearchParams(searchParams)
        }
    }, [searchParams, setSearchParams])

    // when the view filter is updated, reset the plans filter, clear the query and search params
    const updateView = useCallback(
        async (updatedView: string) => {
            const newSearchParams = new URLSearchParams()
            newSearchParams.set('view', updatedView)
            setSearchParams(newSearchParams)
            setIsEditing(false)
            setFilter({ country: [], plan: [], generation: [] })
            resetPlans()
        },
        [setSearchParams, resetPlans]
    )

    // called when onSubmit is called in the header formik form
    const handleSearch = (searchInput: GetPlansForMarketsRequest) => {
        const extraQueryParams = []
        const { country, plan, generation: priceGeneration } = searchInput
        if (country) extraQueryParams.push(joinIfArray(country, '&country='))
        if (plan) extraQueryParams.push(joinIfArray(plan, '&type='))
        if (priceGeneration)
            extraQueryParams.push(joinIfArray(priceGeneration, '&price-generation='))

        // Only fire query when all filters are set
        if (country && plan && priceGeneration) {
            setFilter(searchInput)
        }
    }

    const handleEdit = useCallback(() => {
        const plansWithScheduledChanges = plans?.filter(
            ({ scheduledChanges }) => scheduledChanges && scheduledChanges?.length > 0
        )

        if (!plansWithScheduledChanges || plansWithScheduledChanges?.length === 0)
            return setIsEditing(true)

        const plansWithScheduledChangesNames = plansWithScheduledChanges
            .map(({ name }) => name)
            .join(', ')

        error({
            title: 'Cannot make changes to plans with scheduled changes',
            content: (
                <div>
                    <p>
                        Please remove the following plans from your edit selection, or remove their
                        scheduled updates to continue:
                    </p>
                    <p>{plansWithScheduledChangesNames}</p>
                </div>
            ),
        })
    }, [plans])

    const handleSubmit = async () => {
        setIsEditing(false)
    }

    const handleCancel = useCallback(() => {
        setIsEditing(false)
    }, [])

    const header = useCallback(
        () => (
            <Header
                currentView={searchParams.get('view') as ViewType}
                updateView={updateView}
                isEditing={isEditing}
                onSearch={handleSearch}
                onEdit={handleEdit}
            />
        ),
        [handleEdit, isEditing, updateView, searchParams]
    )

    return (
        <Container>
            <Table
                header={header}
                isEditing={isEditing}
                plans={plans ?? []}
                currentView={searchParams.get('view') as ViewType}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
            />
        </Container>
    )
}

export default EntitlementDashboard
