import type React from 'react'
import { useEffect, useRef, useState, type FC } from 'react'
import { Table, Typography, Space } from 'antd'

import { useRiskAssessment, useStyxCompany } from 'services/deimos/styx-company/styx-company'
import {
    hasCompletedAllRiskChecksV2,
    sortRiskCheckForm,
    useRiskAssessmentOwner,
} from 'pages/compliance/shared/risk-assessment/utils'
import useAnalytics from 'utils/analytics'
import { useUser } from 'providers/user-context'
import type { Analytics } from '@segment/analytics-next'
import type { RiskAssessmentV2, RiskCheckDataV2, RiskTypesV2, UpdateRiskCheck } from 'types/styx'
import styled from 'styled-components'
import { getRiskCheckDataSource } from '../utils/risk-assessment-utils'
import { getRiskTableColumns } from './columns'

const { Text } = Typography

interface Props {
    analytics: Analytics | null
    companyId?: string
    dataSource: RiskCheckDataV2[]
    onUpdateRiskCheck: (
        checkId: string,
        body: UpdateRiskCheck,
        checkType: RiskTypesV2
    ) => Promise<void>
    ownerName: string
    userId?: string | null
}

export const RiskCheckFormV2: FC<React.PropsWithChildren<Props>> = ({
    analytics,
    companyId,
    dataSource,
    onUpdateRiskCheck,
    userId,
}) => {
    const [values, setValues] = useState<RiskCheckDataV2[]>(dataSource)
    const persistedOrder = useRef<RiskCheckDataV2[]>(dataSource)

    useEffect(() => {
        const emptyState: Record<string, RiskCheckDataV2> = {}

        const riskTypeDict: Record<string, RiskCheckDataV2> = dataSource.reduce((acc, check) => {
            acc[check.riskTypeId] = check
            return acc
        }, emptyState)

        const newState = persistedOrder.current.map((riskCheck) => {
            return riskTypeDict[riskCheck.riskTypeId] || { ...riskCheck }
        })

        setValues(newState)
    }, [dataSource])

    const columns = getRiskTableColumns({
        dataSource: values,
        onUpdateRiskCheck,
        analytics,
        companyId,
        userId,
    })

    const expandedRowRender = (record: RiskCheckDataV2) => {
        const childFactors = record.childFactors || []

        const nestedColumns = getRiskTableColumns({
            dataSource: childFactors,
            onUpdateRiskCheck,
            analytics,
            companyId,
            userId,
        })

        return record?.childFactors ? (
            <Table
                columns={nestedColumns}
                dataSource={childFactors}
                pagination={false}
                rowKey="riskType"
                tableLayout="fixed"
                showHeader={false}
            />
        ) : null
    }

    const expandableConfig = {
        expandedRowRender,
        expandedRowKeys: ['PARENT_PERSON_PEP_CHECK'],
        showExpandColumn: false,
    }

    return (
        <Table
            dataSource={values}
            rowKey="riskType"
            columns={columns}
            pagination={false}
            expandable={expandableConfig}
            tableLayout="fixed"
        />
    )
}

const RiskAssessmentFormV2Container: FC<React.PropsWithChildren<unknown>> = () => {
    const { mutations, riskAssessment } = useRiskAssessment()
    const analytics = useAnalytics()
    const { company } = useStyxCompany()
    const user = useUser()

    const riskAssessmentV2 = riskAssessment as unknown as RiskAssessmentV2

    const { ownerName } = useRiskAssessmentOwner()

    const dataSource = getRiskCheckDataSource(riskAssessmentV2)

    const onUpdateRiskCheck = async (
        checkId: string,
        body: UpdateRiskCheck,
        checkType: RiskTypesV2
    ) => await mutations.updateRiskCheck(checkId, body, checkType)

    const hasCompletedAllRiskChecksBool = hasCompletedAllRiskChecksV2(riskAssessmentV2.checks)

    return (
        <Space direction="vertical">
            {!hasCompletedAllRiskChecksBool && (
                <>
                    <Text>Suggested risk score: {riskAssessmentV2.customerRiskRating?.score}</Text>
                    <Text>
                        Total factors processed:
                        {` ${riskAssessmentV2.customerRiskRating?.factorsProcessed} / ${riskAssessmentV2.customerRiskRating?.factors}`}
                    </Text>
                </>
            )}

            <RiskCheckFormV2
                analytics={analytics}
                companyId={company?.globalId}
                data-testid="risk-check-form-v2"
                dataSource={sortRiskCheckForm<RiskCheckDataV2>(dataSource)}
                onUpdateRiskCheck={onUpdateRiskCheck}
                ownerName={ownerName}
                userId={user.id}
            />
        </Space>
    )
}

export const StyledSubTable = styled.div`
    .ant-table {
        margin-inline: 0;
    }
`

export default RiskAssessmentFormV2Container
