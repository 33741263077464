import { useState } from 'react'
import { Alert, Button, Card, Modal, Tag, Typography } from 'antd'
import dayjs from 'dayjs'
import { bff } from 'pages/compliance/bff'
import styled, { css } from 'styled-components'

import type { CompanyStakeholderResponse, PersonNameModel } from 'bff/moons/generated/styx-v1'
import { AlertOutlined } from '@ant-design/icons'
import { formatTimestamp } from 'packages/dates/dates'
import type { KycResult } from 'types/styx'

const { Text } = Typography

type Props = {
    globalId: string
    companyId: string
    hasFdd: boolean
}

type CompanyManagementProps = {
    data: CompanyStakeholderResponse[] | undefined
}

type LatestCheckPerPerson = {
    name: PersonNameModel | undefined
    result: KycResult
    falsePositive?: boolean
    isOutdated: boolean
    createdAt: string
}

export const SisCheckStatus = ({ globalId, companyId, hasFdd }: Props) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { data }: CompanyManagementProps = bff.people.getCompanyManagement.useQuery({
        id: globalId,
        styxId: companyId,
        snapshot: '',
    })

    const latestCheckPerPerson: LatestCheckPerPerson[] = []

    data?.map((person) => {
        const latestSisCheck = person?.sisChecks?.sort((a, b) =>
            dayjs(a?.createdAt).isAfter(dayjs(b?.createdAt)) ? -1 : 1
        )?.[0]

        const isOutdated = dayjs(new Date()).diff(latestSisCheck?.createdAt, 'days') > 7

        latestCheckPerPerson.push({
            name: person?.name,
            result: latestSisCheck?.result as KycResult,
            falsePositive: latestSisCheck?.falsePositive,
            isOutdated,
            createdAt: latestSisCheck?.createdAt,
        })
    })

    const sisChecksContainFail = latestCheckPerPerson?.some(
        (check) => check?.result === 'FAIL' && check?.falsePositive === false
    )

    const sisChecksUnchecked =
        latestCheckPerPerson.length === 0 ||
        latestCheckPerPerson.every((check) => check?.result === 'UNCHECKED')

    const sisChecksPass = latestCheckPerPerson?.every(
        (check) =>
            check?.result === 'PASS' ||
            check?.result === 'Pass' ||
            check?.result === 'MANUALLY_APPROVED' ||
            (check?.result === 'FAIL' && check?.falsePositive === true)
    )

    const sisChecksOutdated = latestCheckPerPerson?.some((check) => check?.isOutdated) && !hasFdd

    const sisCheckStatus = sisChecksUnchecked
        ? 'UNCHECKED'
        : sisChecksOutdated
          ? 'OUTDATED'
          : sisChecksPass
            ? 'PASS'
            : sisChecksContainFail
              ? 'FAIL'
              : 'UNKNOWN'

    const sisCheckTagColor = (status: string) =>
        status === 'UNCHECKED' || status === 'UNKNOWN'
            ? 'blue'
            : status === 'OUTDATED'
              ? 'orange'
              : status === 'FAIL'
                ? 'red'
                : 'green'

    return (
        <>
            <SisCheckButton
                variant="outlined"
                onClick={() => setIsModalOpen(true)}
                color={
                    sisCheckStatus === 'UNCHECKED' ||
                    sisCheckStatus === 'UNKNOWN' ||
                    sisCheckStatus === 'OUTDATED'
                        ? 'default'
                        : sisCheckStatus === 'FAIL'
                          ? 'danger'
                          : 'primary'
                }
                disabled={sisCheckStatus === 'UNCHECKED' || sisCheckStatus === 'UNKNOWN'}
            >
                {`Person SIS Check: ${sisCheckStatus.toLocaleUpperCase()}`}
            </SisCheckButton>
            <Modal
                title="Person SIS Check"
                footer={null}
                centered
                width={500}
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
            >
                <>
                    {sisChecksOutdated && (
                        <Alert
                            type="warning"
                            showIcon
                            message="Checks are outdated, they must be re-run to FDD company"
                        />
                    )}
                    {latestCheckPerPerson.map((check, index) => (
                        <Card key={index} style={{ padding: 0, margin: 5 }}>
                            <p>
                                <Tag color={sisCheckTagColor(check?.result || 'UNKNOWN')}>
                                    {check?.result}
                                </Tag>
                                {check?.falsePositive && (
                                    <Tag color="gold">
                                        <AlertOutlined /> False Positive
                                    </Tag>
                                )}
                                <Text type="secondary" style={{ fontSize: 12, float: 'right' }}>
                                    {formatTimestamp(check.createdAt)}
                                </Text>
                            </p>
                            <p style={{ marginTop: 5 }}>
                                {`${check?.name?.givenName} ${check?.name?.familyName}`}
                            </p>
                        </Card>
                    ))}
                </>
            </Modal>
        </>
    )
}

const SisCheckButton = styled(Button)`
    ${({ color }) =>
        color === 'default' &&
        css`
            border-color: #d9d9d9 !important;
            background: #ffffff !important;
            &:hover {
                color: #4096ff !important;
                border-color: #4096ff !important;
                background: #ffffff !important;
            }
        `}

    ${({ color }) =>
        color === 'primary' &&
        css`
            color: rgb(82, 196, 26) !important;
            border-color: rgb(82, 196, 26) !important;
            background: rgb(246, 255, 237) !important;
            &:hover {
                color: #4096ff !important;
                border-color: #4096ff !important;
                background: #ffffff !important;
            }
        `}

    ${({ color }) =>
        color === 'danger' &&
        css`
            color: rgb(245, 34, 45) !important;
            border-color: rgb(245, 34, 45) !important;
            background: rgb(255, 241, 240) !important;
            &:hover {
                color: #4096ff !important;
                border-color: #4096ff !important;
                background: #ffffff !important;
            }
        `}
`
