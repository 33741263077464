import type { GetPlansInputType } from './plans/plans.bff'
import type { GetPlansForMarketsRequest } from './types'

export const joinIfArray = (query: string[] | string, delimiter = ',') =>
    Array.isArray(query) ? `${delimiter}${query.join(delimiter)}` : `${delimiter}${query}`

export const getPlansInputFromQuery = (query: GetPlansForMarketsRequest): GetPlansInputType => {
    const base: string[] = []
    return {
        countries: query.country ? base.concat(query.country) : [],
        types: query.plan ? base.concat(query.plan) : [],
        priceGeneration: Array.isArray(query.generation) ? query.generation : [query.generation],
    }
}

export const isFilterReady = (filter: GetPlansInputType): boolean => {
    return (
        filter.countries.length > 0 && filter.types.length > 0 && filter.priceGeneration.length > 0
    )
}
