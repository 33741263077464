import { Space, Tag, Typography } from 'antd'
import { bff } from '../../bff'
import type { OnboardingSource } from 'types/deimos-company'
import type { CompanyOffboardingStatus } from '@pleo-io/deimos'
import { OnboardedViaTag } from '../../shared/company-onboarded-via-tag'
import { PartnerReferralTag } from '../../shared/partner-referral-tag/partner-referral-tag'

const { Link } = Typography

export const Tags = ({
    onboardedVia,
    isManualSignup,
    offboardingStatus,
    companyId,
    isPreviouslyOffboarded,
}: {
    onboardedVia: OnboardingSource
    isManualSignup: boolean
    offboardingStatus?: CompanyOffboardingStatus | null
    companyId: string
    isPreviouslyOffboarded: boolean
}) => {
    const { data: spendDetails } = bff.tags.getsCompanySpendStatus.useQuery({ companyId })

    return (
        <div style={{ fontWeight: 400 }}>
            <Space>
                <OnboardedViaTag onboardedVia={onboardedVia as OnboardingSource} />
                {isManualSignup && <Tag color="warning">Manual signup</Tag>}
                {!!offboardingStatus && (
                    <Tag color="blue">
                        <Link href={`/customer-success/offboarding/${companyId}`}>Offboarding</Link>
                    </Tag>
                )}
                {isPreviouslyOffboarded && <Tag color="warning">Previously offboarded</Tag>}
                {spendDetails?.status && (
                    <>
                        {spendDetails.status === 'BLOCKED' ? (
                            <Tag color="red">Company spending: Blocked</Tag>
                        ) : spendDetails.status === 'LIVE' ? (
                            <Tag color="green">Company spending: Live</Tag>
                        ) : (
                            <Tag color="orange">Company spending: Closed</Tag>
                        )}
                    </>
                )}

                <PartnerReferralTag deimosCompanyId={companyId} />
            </Space>
        </div>
    )
}
