import { LoadingOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { bff } from 'pages/compliance/bff'

const mapRegistryToHumanReadableName = {
    COMPANIES_HOUSE: 'Companies House',
    CREDITSAFE_GLOBAL: 'Creditsafe',
    CREDITSAFE_SWEDEN: 'Creditsafe',
    VIRK: 'Virk',
    VEMBI: 'Vembi',
    BVD: 'BVD/Orbis',
}

export const CompanyWebsiteInformationSource = ({ companyId }: { companyId: string }) => {
    const { data, error, isLoading } = bff.companyWebsiteInformationSource.getData.useQuery({
        companyId,
    })

    if (isLoading) {
        return (
            <Tag icon={<LoadingOutlined />} color="processing">
                Loading...
            </Tag>
        )
    }

    if (error) {
        return <Tag color="red">Error fetching source</Tag>
    }

    if (data.source === 'UNKNOWN') {
        return <Tag color="red">Unknown source</Tag>
    }

    if (data.source === 'USER_INPUT') {
        return <Tag color="yellow">User input</Tag>
    }

    if (data.source === 'DUE_DILIGENCE_AGENT_INPUT') {
        return <Tag color="green">Due diligence agent input</Tag>
    }

    return <Tag color="blue">{mapRegistryToHumanReadableName[data.source]}</Tag>
}
