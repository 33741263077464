import { Breadcrumb, Input, Select, Space, Table, Tag } from 'antd'
import { Link } from 'react-router-dom'

import { PageContentLayout } from 'components/layout-containers'

import { OddListProvider } from 'features/odd/context-list'
import { CompanyOffboardedModal, CompanyOffboardedInitiatedModal } from 'features/odd/modals'
import { bff } from '../bff-hooks'
import { useState } from 'react'
import { StatusTag } from 'features/odd/status-tag'
import { SearchCasesStateItem } from 'bff/moons/generated/case-manager'

const PAGE_SIZE = 20

export const OddAuditHub = () => {
    const [page, setPage] = useState(1)
    const [companyId, setCompanyId] = useState<string | null>(null)
    const [status, setStatus] = useState<SearchCasesStateItem>('WAITING_FOR_CUSTOMER')
    const { data, isLoading } = bff.oddAuditHubPage.searchOddList.useQuery({
        offset: (page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        entityId: companyId || undefined,
        state: [status],
    })

    return (
        <OddListProvider>
            <PageContentLayout>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to={'/compliance'}>Compliance</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to={'/compliance/odd'}>Ongoing Due Diligence</Link>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Space>
                    <Input
                        placeholder="Company ID"
                        value={companyId || ''}
                        onChange={(e) => setCompanyId(e.target.value)}
                        style={{ width: 400 }}
                        autoFocus
                    />
                    <Select
                        placeholder="State"
                        value={status}
                        onChange={(value) => setStatus(value)}
                        style={{ width: 300, marginLeft: 16 }}
                        options={Object.values(SearchCasesStateItem).map((state) => ({
                            label: state,
                            value: state,
                        }))}
                    />
                </Space>
                <Table
                    size="small"
                    loading={isLoading}
                    dataSource={data?.data}
                    columns={[
                        {
                            title: 'Company ID',
                            dataIndex: 'entityId',
                            key: 'entityId',
                            render: (value, record) => (
                                <Link
                                    target="_blank"
                                    to={`/compliance/companies/${record.entityId}`}
                                >
                                    {record.entityId}
                                </Link>
                            ),
                        },
                        {
                            title: 'State',
                            dataIndex: 'state',
                            key: 'state',
                            render: (value) => <StatusTag status={value} />,
                        },
                        {
                            title: 'Missing Info',
                            dataIndex: 'missingInformationCount',
                            key: 'missingInformationCount',
                        },
                        {
                            title: 'Waiting without missing info',
                            dataIndex: 'waitingWithoutMissingInformation',
                            key: 'waitingWithoutMissingInformation',
                            render: (value) =>
                                value ? (
                                    <Tag color="error">Yes</Tag>
                                ) : (
                                    <Tag color="success">No</Tag>
                                ),
                        },
                        {
                            title: 'Case',
                            dataIndex: 'id',
                            key: 'id',
                            render: (value, record) => (
                                <Link target="_blank" to={`/compliance/odd/${record.id}`}>
                                    View
                                </Link>
                            ),
                        },
                    ]}
                    pagination={{
                        current: page,
                        onChange: (newPage) => setPage(newPage),
                        total: data?.pagination.total,
                        showSizeChanger: false,
                        pageSize: PAGE_SIZE,
                        showTotal: (total) => `${total} cases`,
                    }}
                    rowKey="id"
                />
            </PageContentLayout>
            <CompanyOffboardedModal />
            <CompanyOffboardedInitiatedModal />
        </OddListProvider>
    )
}
