import type { CreateDemoAccountRequest } from '../../../types/demo-accounts'
import type { FormValuesExtended } from './generate-demo/generate-demo-form'

export const formValuesToCreateDemoAccountRequest = (
    formValues: FormValuesExtended,
    requesterEmail?: string
): CreateDemoAccountRequest => ({
    companyName: formValues.companyName,
    country: formValues.country,
    expenses: {
        randomExpenseCount: formValues.numberOfExpenses,
        expensePreset: {
            country: formValues.country,
        },
    },
    initialWalletLoadAmount: formValues.walletBalance,
    memberEmployeesCount: formValues.numberOfEmployees,
    language: formValues.language,
    requesterEmail,
    expiresAt: formValues.expiresAt,
})
