import { Table, Typography } from 'antd'
import Spinner from 'components/content-spinner'
import { ContentContainer } from 'components/layout-containers'
import { useParams } from 'react-router-dom'
import { bff } from '../bff'
const { Text } = Typography

export const PartnerTeams = () => {
    const { id } = useParams()

    const { data = [], isLoading } = bff.partnerTeams.getTeams.useQuery({ partnerId: id })

    return (
        <ContentContainer>
            {isLoading ? (
                <Spinner />
            ) : (
                <Table
                    loading={false}
                    rowKey="id"
                    dataSource={data}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            render: (name) => <Text>{name}</Text>,
                        },
                        {
                            title: 'Description',
                            dataIndex: 'description',
                            render: (description) => <Text>{description}</Text>,
                        },
                        {
                            title: 'Members',
                            dataIndex: 'userIds',
                            render: (userIds) => <Text>{userIds.length}</Text>,
                        },
                        {
                            title: 'Clients',
                            dataIndex: 'companyIds',
                            render: (companyIds) => <Text>{companyIds.length}</Text>,
                        },
                    ]}
                />
            )}
        </ContentContainer>
    )
}
