import { Button, DatePicker, Modal, Space } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

interface Props {
    isOpen: boolean
    isLoading: boolean
    onCancel: () => void
    onSubmit: (date: string) => void
}

export default function ExpiryDateChangeModal({ isLoading, isOpen, onCancel, onSubmit }: Props) {
    const [selectedDate, setSelectedDate] = useState<string | null>(null)

    const handleSubmit = () => {
        if (selectedDate) {
            const isoString = new Date(selectedDate).toISOString()
            onSubmit(isoString)
        }
    }

    return (
        <Modal
            open={isOpen}
            onCancel={onCancel}
            footer={null}
            centered
            title="Change expiry date"
            data-testid="expiry-date-change-modal"
        >
            <StyledSpace direction="vertical" size="middle">
                <DatePicker
                    aria-label="Expiry date"
                    onChange={(_, date) => setSelectedDate(date as string)}
                />
                <Button
                    type="primary"
                    onClick={handleSubmit}
                    loading={isLoading}
                    disabled={!selectedDate}
                >
                    Change date
                </Button>
            </StyledSpace>
        </Modal>
    )
}

const StyledSpace = styled(Space)`
    width: 100%;
`
