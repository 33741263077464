import { EditOutlined } from '@ant-design/icons'
import { Alert, Button, Col, message, Modal, Row, Space, Statistic, Typography } from 'antd'
import ErrorState from 'components/error-state/error-state'
import { ContentContainer } from 'components/layout-containers'
import { Formik } from 'formik'
import { Form, FormItem, Input, Select } from 'formik-antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { PartnerTier } from 'types/partner'
import * as Yup from 'yup'
import { bff } from './bff'

const { Text } = Typography

const tierNames: Record<PartnerTier, string> = {
    [PartnerTier.TRIAL]: 'New partner',
    [PartnerTier.BRONZE]: 'Bronze',
    [PartnerTier.SILVER]: 'Silver',
    [PartnerTier.GOLD]: 'Gold',
    [PartnerTier.GOLD_DACH]: 'Gold',
    [PartnerTier.PLATINUM]: 'Platinum',
    [PartnerTier.PLATINUM_DACH]: 'Platinum',
}

const channelNames: Record<'accounting' | 'consulting', string> = {
    accounting: 'Accounting',
    consulting: 'Consulting',
}

export const PartnerProgram = () => {
    const { id } = useParams()
    const [showOverrideTier, setShowOverrideTier] = useState(false)
    const [showUpdateChannel, setShowUpdateChannel] = useState(false)
    const { data, isLoading, isError, refetch } = bff.partnerProgram.getPartnerProgramInfo.useQuery(
        { partnerId: id }
    )

    if (!data && isError && !isLoading) {
        return (
            <ContentContainer>
                <ErrorState onRetry={() => refetch()} />
            </ContentContainer>
        )
    }

    return (
        <ContentContainer>
            <Space direction="vertical" size="middle">
                {data?.calculatedTier && (
                    <Alert
                        description={
                            data.onboardingPeriod.daysToEnd > 0
                                ? `This partner's tier has an override. Prior to this, they would be on the New Partner tier.`
                                : `This partner tier has an override. Without the override, they would be a ${
                                      tierNames[data.calculatedTier.type]
                                  } tier partner.`
                        }
                        type="warning"
                    />
                )}
                <Row justify="space-between" gutter={[24, 24]}>
                    <Col span={8}>
                        <Row align="bottom">
                            <Statistic
                                loading={!data}
                                title="Partner Channel"
                                value={
                                    data?.partnerChannel ? channelNames[data.partnerChannel] : '-'
                                }
                                suffix={
                                    <Button
                                        onClick={() => setShowUpdateChannel(true)}
                                        type="link"
                                        style={{ padding: 4 }}
                                    >
                                        <EditOutlined /> update
                                    </Button>
                                }
                            />
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row align="bottom">
                            <Statistic
                                loading={!data}
                                title="Current Tier"
                                value={data?.currentTier ? tierNames[data.currentTier.type] : '-'}
                                suffix={
                                    <Button
                                        onClick={() => setShowOverrideTier(true)}
                                        type="link"
                                        style={{ padding: 4 }}
                                    >
                                        <EditOutlined /> override
                                    </Button>
                                }
                            />
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Partnership year"
                            value={data?.partnershipYear.daysToEnd || 0}
                            suffix={<Text style={{ fontSize: 16 }}> days left</Text>}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Onboarding period"
                            value={data?.onboardingPeriod.daysToEnd || 0}
                            suffix={<Text style={{ fontSize: 16 }}> days left</Text>}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Free users"
                            value={data?.partnershipYear.freeUsers || 0}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="Paid users"
                            value={data?.partnershipYear.paidUsers || 0}
                        />
                    </Col>
                    <Col span={8}>
                        <Statistic
                            loading={!data}
                            title="New clients"
                            value={data?.partnershipYear.newClients || 0}
                        />
                    </Col>
                </Row>
            </Space>
            <OverrideTier close={() => setShowOverrideTier(false)} visible={showOverrideTier} />
            <UpdatePartnerChannel
                partnerChannel={data?.partnerChannel}
                close={() => setShowUpdateChannel(false)}
                visible={showUpdateChannel}
            />
        </ContentContainer>
    )
}

const overrideTierValidationSchema = Yup.object().shape({
    newTier: Yup.string().required('Required'),
    message: Yup.string().required('Required'),
})

function OverrideTier({ close, visible }: { close: () => void; visible: boolean }) {
    const { id } = useParams()
    const { mutateAsync } = bff.partnerProgram.overridePartnerProgramTier.useMutation({
        onSuccess() {
            message.success('Partner tier successfully overridden.')
            close()
        },
        onError(error) {
            if (error.message) {
                message.error(`Could not override partner tier: ${error.message}`)
            } else {
                message.error('Could not override partner tier. Please try again later.')
            }
        },
    })

    return (
        <Modal title="Override Partner Tier" open={visible} onCancel={close} footer={null}>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                <Alert
                    description={`You will override the existing partner tiers for the current partnership year`}
                    type="warning"
                />
                <Formik
                    initialValues={{
                        newTier: 'BRONZE' as const,
                        message: '',
                    }}
                    validationSchema={overrideTierValidationSchema}
                    onSubmit={async (values) => await mutateAsync({ partnerId: id, ...values })}
                    validateOnMount
                >
                    {({ isValid, isSubmitting }) => (
                        <Form layout="vertical">
                            <FormItem name="newTier" label="Select new tier">
                                <Select name="newTier" style={{ marginTop: 8, width: '100%' }}>
                                    <Select.Option value={'BRONZE' as const}>Bronze</Select.Option>
                                    <Select.Option value={'SILVER' as const}>Silver</Select.Option>
                                    <Select.Option value={'GOLD' as const}>Gold</Select.Option>
                                    <Select.Option value={'PLATINUM' as const}>
                                        Platinum
                                    </Select.Option>
                                </Select>
                            </FormItem>
                            <FormItem name="message" label="Reason for overriding">
                                <Input.TextArea
                                    rows={3}
                                    name="message"
                                    placeholder="Why was an override needed?"
                                />
                            </FormItem>
                            <Row justify="end">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isSubmitting}
                                    disabled={!isValid}
                                >
                                    Override
                                </Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Space>
        </Modal>
    )
}

const updateChannelValidationSchema = Yup.object().shape({
    partnerChannel: Yup.string().required('Required'),
})

function UpdatePartnerChannel({
    partnerChannel = 'accounting',
    close,
    visible,
}: {
    partnerChannel?: 'accounting' | 'consulting'
    close: () => void
    visible: boolean
}) {
    const { id } = useParams()
    const { mutateAsync } = bff.partnerProgram.updatePartnerChannel.useMutation({
        onSuccess() {
            message.success('Partner channel has been successfully updated.')
            close()
        },
        onError(error) {
            if (error.message) {
                message.error(`Could not update partner channel: ${error.message}`)
            } else {
                message.error('Could not update partner channel. Please try again later.')
            }
        },
    })

    return (
        <Modal title="Update Partner Channel" open={visible} onCancel={close} footer={null}>
            <Space direction="vertical" size="large" style={{ display: 'flex' }}>
                <Alert
                    description={`You will update the current partner channel.`}
                    type="warning"
                />
                <Formik
                    initialValues={{ partnerChannel }}
                    validationSchema={updateChannelValidationSchema}
                    onSubmit={async (values) => await mutateAsync({ partnerId: id, ...values })}
                    validateOnMount
                >
                    {({ isValid, isSubmitting }) => (
                        <Form layout="vertical">
                            <FormItem name="partnerChannel" label="Select new channel">
                                <Select
                                    name="partnerChannel"
                                    style={{ marginTop: 8, width: '100%' }}
                                >
                                    <Select.Option value={'accounting' as const}>
                                        Accounting
                                    </Select.Option>
                                    <Select.Option value={'consulting' as const}>
                                        Consulting
                                    </Select.Option>
                                </Select>
                            </FormItem>
                            <Row justify="end">
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    loading={isSubmitting}
                                    disabled={!isValid}
                                >
                                    Update
                                </Button>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Space>
        </Modal>
    )
}
