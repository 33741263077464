import { takeLatest, call, put } from 'redux-saga/effects'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Typography, notification } from 'antd'

import type { EmployeePayload } from 'types/employee'
import type { UserRole } from 'types/user-role'
import { getEmployees } from 'services/deimos/companies'
import * as employeeApi from 'services/deimos/employees'
import * as userApi from 'services/kerberos/users'
import { actions as stateActions } from 'store/modules/support/slice'

import { actions } from './slice'
import { EmployeeType } from '@pleo-io/deimos'
import { ArrowRightOutlined } from '@ant-design/icons'

export function* fetchCompanyEmployees({
    payload: { deimosId, includeDeleted },
}: PayloadAction<{ deimosId: string; includeDeleted: boolean }>): any {
    try {
        yield put(stateActions.modifiedStatus({ status: 'fetching', api: 'employees' }))

        const result = yield call(getEmployees, deimosId, includeDeleted, EmployeeType.COMPANY)
        const resolvedRes = yield result.json()
        if (resolvedRes.length === 0) {
            throw new Error('Not Found')
        }
        yield put(actions.updatedCompanyEmployees(resolvedRes))
        yield put(stateActions.modifiedStatus({ status: 'resolved', api: 'employees' }))
    } catch (e) {
        yield put(actions.resetCompanyEmployees())
        yield put(stateActions.modifiedStatus({ status: 'error', api: 'employees' }))

        if (!(e as Error).toString().includes('Not Found')) {
            yield call(notification.error, {
                message: 'Unable to fetch company employees.',
                description: (e as Error).message,
            })
        }
    }
}

export function* reactivateEmployee({
    payload: { employeeId, userId },
}: PayloadAction<{ employeeId: string; userId: string }>): any {
    try {
        yield put(actions.modifiedStatus('fetching'))
        const res = yield call(employeeApi.restoreEmployee, employeeId, userId)
        const restoreResponse = yield res.json()

        yield put(actions.updateEmployee(restoreResponse))
        yield put(actions.modifiedStatus('resolved'))
    } catch (e) {
        yield put(actions.modifiedStatus('error'))
        yield call(notification.error, {
            message: 'Unable to restore user',
            description: (e as Error).message,
        })
    }
}

export function* deactivateEmployees({ payload: employees }: PayloadAction<EmployeePayload[]>) {
    yield put(actions.modifiedStatus('fetching'))
    let errorCount = 0
    for (let i = 0; i < employees.length; i++) {
        try {
            const { employeeId } = employees[i]
            yield call(employeeApi.deactivateEmployee, employeeId)
            yield put(actions.deactivatedEmployee(employeeId))
        } catch {
            errorCount++
        }
    }
    yield put(actions.modifiedStatus('resolved'))
    yield call(notification.success, {
        message: `Deactivated ${employees.length - errorCount} employees`,
        description: errorCount ? `Failed to deactivate ${errorCount} employees` : '',
    })
}

const InviteError = (email: string, userId?: string) => {
    if (!userId) {
        return <Typography.Text>{email}</Typography.Text>
    }
    return (
        <Typography.Text>
            Invite failed for email {email}. They are a partner user with an orphan employee record.
            Go to the{' '}
            <a href={`/customer-success/users/${userId}`}>
                user profile <ArrowRightOutlined />
            </a>{' '}
            and delete the employee record.
        </Typography.Text>
    )
}

export function* inviteEmployee({
    payload: { email, firstName, companyId },
}: PayloadAction<{ email: string; firstName?: string; companyId: string }>): any {
    let userId: string | undefined
    try {
        yield put(actions.modifiedStatus('fetching'))
        const res = yield call(employeeApi.inviteEmployee, companyId, email, firstName)
        const inviteResponse = yield res.json()

        const { success } = inviteResponse

        if (!success) {
            const response = yield call(userApi.getUsers, { email })
            const users = yield response.json()
            const hasOrphanEmployeeRole = users[0]?.roles.some(
                (role: UserRole) => role.resource === 'employee' && role.parentResource === null
            )
            const hasPartnerRole = users[0]?.roles.some(
                (role: UserRole) => role.resource === 'partner'
            )
            if (hasOrphanEmployeeRole && hasPartnerRole) {
                userId = users[0]?.id
            }
            throw new Error('Invite failed')
        }

        yield put(actions.addedEmployee(inviteResponse.employee))
        yield put(actions.modifiedStatus('resolved'))
        yield call(notification.success, {
            message: 'Invite sent successfully',
            description: `Invite sent to ${email}`,
        })
    } catch (e) {
        yield put(actions.modifiedStatus('error'))
        yield call(notification.error, {
            message: 'Send invite failed',
            description:
                (e as Error).message === 'Invite failed'
                    ? InviteError(email, userId)
                    : (e as Error).message,
            duration: null,
        })
    }
}

export function* employeesSaga() {
    yield takeLatest(actions.deactivateEmployees, deactivateEmployees)
    yield takeLatest(actions.fetchCompanyEmployees, fetchCompanyEmployees)
    yield takeLatest(actions.inviteEmployee, inviteEmployee)
    yield takeLatest(actions.reactivateEmployee, reactivateEmployee)
}
