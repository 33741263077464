import type {
    BulkUpdatePlanParams,
    FeatureResponse,
    PlanResponse,
} from 'bff/moons/generated/janus-v1'
import { ViewType } from '../../enums'
import { merge, startCase } from 'lodash'
import type {
    Feature,
    FormikStructure,
    GenerateTableStructure,
    Limit,
    LimitResponse,
    Record,
    ResponseLimit,
} from '../types'
import {
    allKey,
    defaultLimitValue,
    fieldNameDelimiter,
    fullStop,
    fullStopReplacement,
} from './constants'
import { FieldNameParts, FormDataTypes, RecordDatatype } from '../enums'
import dayjs, { Dayjs } from 'packages/dayjs'

export const deDupeArray = <T,>(array: T[]) => [...new Set([...array])]

const findPlanByType = (currentPlan: PlanResponse) => (plan: PlanResponse) =>
    plan.type === currentPlan.type

const combineArrayToObject =
    <O,>(baseObject: { [key: string]: O }) =>
    <T,>(mergedObject: T, key: string) => ({
        ...mergedObject,
        [key]: baseObject[key],
    })

export const generateDefaultLimits = (limits: Array<LimitResponse>) =>
    limits.reduce<GenerateTableStructure>(
        (mergedLimits: GenerateTableStructure, limit) => ({
            ...mergedLimits,
            [limit.name]: {
                all: '',
                id: limit.name,
                label: startCase(limit.name),
                key: limit.name,
                type: RecordDatatype.Input,
            },
        }),
        {}
    )

export const generateDefaultEntitlementSettings = (
    entitlements: Array<Pick<FeatureResponse, 'name' | 'id'>>
) =>
    entitlements.reduce<GenerateTableStructure>(
        (mergedEntitlements: GenerateTableStructure, entitlement) => ({
            ...mergedEntitlements,
            [entitlement.id]: {
                all: '',
                id: entitlement.id,
                label: entitlement.name,
                key: entitlement.name,
                type: RecordDatatype.Feature,
            },
        }),
        {}
    )

export const generateEntitlementSettingsForPlan = (
    entitlements: Array<Pick<FeatureResponse, 'name' | 'id'>>,
    currentPlan: PlanResponse,
    currentView: ViewType,
    currentScheduledPlan?: PlanResponse
) => {
    const mapFeatureName = (feature: FeatureResponse) => feature.name
    const planEntitlementNames = currentPlan.features?.map(mapFeatureName)
    const planAddonNames = currentPlan.addons?.map(mapFeatureName)
    const scheduledPlanCombinedFeatures = [
        ...(currentScheduledPlan?.features ?? []),
        ...(currentScheduledPlan?.addons ?? []),
    ]
    const scheduledPlanCombinedFeaturesName = scheduledPlanCombinedFeatures.map(mapFeatureName)
    const dataIndex =
        currentView === ViewType.Market ? escapeFullStop(currentPlan.name) : currentPlan.country
    const combineEntitlementSettingsForDataIndex = (
        entitlementAcc = {},
        currEntitlement: Pick<FeatureResponse, 'name' | 'id'>
    ) => {
        const isAddOn = planAddonNames?.includes(currEntitlement.name)
        const isScheduled = scheduledPlanCombinedFeaturesName.includes(currEntitlement.name)
        const isEnabled = planEntitlementNames?.includes(currEntitlement.name) || isAddOn
        return {
            ...entitlementAcc,
            [currEntitlement.id]: {
                [dataIndex]: {
                    isEnabled,
                    isScheduled,
                    isAddOn,
                },
            },
        }
    }
    return entitlements.reduce(combineEntitlementSettingsForDataIndex, {})
}

export const escapeFullStop = (planName: string) => {
    return planName.replace(fullStop, fullStopReplacement)
}

export const generateLimitsSettingsForPlan = (
    limits: Array<Pick<LimitResponse, 'name' | 'type'>>,
    currentPlan: PlanResponse,
    currentView: ViewType
) => {
    const dataIndex =
        currentView === ViewType.Market ? escapeFullStop(currentPlan.name) : currentPlan.country
    const currentLimits = currentPlan.limits as ResponseLimit

    const extractLimitValueForDataIndex = (
        limitAcc = {},
        currLimit: Pick<LimitResponse, 'name' | 'type'>
    ) => ({
        ...limitAcc,
        [currLimit.name]: {
            [dataIndex]: currentLimits[currLimit.name] ?? '',
        },
    })

    return limits.reduce(extractLimitValueForDataIndex, {})
}

export const checkForSettingsInEveryPlan = (record: Record) => {
    const { label, all, key, id, type, ...columns } = record
    const planColumns = Object.keys(columns)
    const planSettingsMap = planColumns.map((dataIndex) => {
        const { isEnabled, isAddOn } = columns[dataIndex] as Feature
        return {
            isEnabled,
            isAddOn,
        }
    })

    const allEnabled = planSettingsMap.every(({ isEnabled }) => isEnabled)
    const allAddon = planSettingsMap.every(({ isAddOn }) => isAddOn)
    return {
        isEnabled: allEnabled,
        isAddOn: allAddon,
    }
}

export const checkForLimitsInEveryPlan = (record: Record) => {
    const { label, all, key, id, type, ...columns } = record
    const planColumns = Object.keys(columns)
    const limits = planColumns.map((dataIndex) => {
        return columns[dataIndex] as Limit
    })

    const allLimitsTheSame = limits.every((limit) => limit === limits[0])

    return allLimitsTheSame ? limits[0] : defaultLimitValue
}

export const generateLimitTableData = (
    limits: Array<LimitResponse>,
    plans: PlanResponse[],
    currentView: ViewType
): GenerateTableStructure => {
    const baseLimits = limits.map(({ name, type }) => ({ name, type }))
    const defaultLimits = generateDefaultLimits(limits)

    const generatePlanLimitsAndMergeWithDefault = (
        mergedPlans: GenerateTableStructure,
        currentPlan: PlanResponse
    ) => {
        const planLimits = generateLimitsSettingsForPlan(baseLimits, currentPlan, currentView)
        return merge({}, mergedPlans, planLimits)
    }

    const limitsWithPlanValues = plans.reduce(generatePlanLimitsAndMergeWithDefault, defaultLimits)

    const generateAllLimitsForPlans = (mergedPlans: GenerateTableStructure, limitId: string) => {
        const all = checkForLimitsInEveryPlan(limitsWithPlanValues[limitId])
        return {
            ...mergedPlans,
            [limitId]: {
                ...limitsWithPlanValues[limitId],
                all,
            },
        } as GenerateTableStructure
    }

    const limitKeys = Object.keys(limitsWithPlanValues)
    return limitKeys.reduce(generateAllLimitsForPlans, limitsWithPlanValues)
}

export const generateEntitlementTableData = (
    entitlements: Array<FeatureResponse>,
    plans: PlanResponse[],
    currentView: ViewType,
    scheduledPlans: PlanResponse[]
): GenerateTableStructure => {
    const baseEntitlements = entitlements.map(({ name, id }) => ({ name, id }))
    const defaultEntitlementSettings = generateDefaultEntitlementSettings(baseEntitlements)

    const generatePlanSettingsAndMergeWithDefault = (
        mergedPlans: GenerateTableStructure,
        currentPlan: PlanResponse
    ) => {
        const currentScheduledPlan = scheduledPlans?.find(findPlanByType(currentPlan))
        const planEntitlements = generateEntitlementSettingsForPlan(
            baseEntitlements,
            currentPlan,
            currentView,
            currentScheduledPlan
        )
        return merge({}, mergedPlans, planEntitlements)
    }
    const entitlementsWithPlanSettings = plans.reduce(
        generatePlanSettingsAndMergeWithDefault,
        defaultEntitlementSettings
    )

    const generateAllSettingsForPlans = (
        mergedPlans: GenerateTableStructure,
        entitlementId: string
    ) => {
        const all = checkForSettingsInEveryPlan(entitlementsWithPlanSettings[entitlementId])
        return {
            ...(mergedPlans ?? {}),
            [entitlementId]: {
                ...entitlementsWithPlanSettings[entitlementId],
                all: all,
            },
        } as GenerateTableStructure
    }

    const entitlementKeys = Object.keys(entitlementsWithPlanSettings)

    return entitlementKeys.reduce(generateAllSettingsForPlans, entitlementsWithPlanSettings)
}

export const filterUnchangedValuesInForm = (
    currentFields: string[],
    updatedFields: FormikStructure,
    type: FormDataTypes
): string[] => {
    return Object.keys(updatedFields).filter((field) => {
        const fieldValue = updatedFields[field]
        const [, , fieldID, fieldType] = field.split(fieldNameDelimiter)

        if (type !== fieldType) return false

        const isInPlan = currentFields.includes(fieldID)
        const addToPlan = !isInPlan && fieldValue
        const removeFromPlan = isInPlan && !fieldValue
        return removeFromPlan || addToPlan
    })
}

export const splitFeaturesAndAddonsIds = (formFields: string[]): [string[], string[]] => {
    return formFields.reduce<[string[], string[]]>(
        (acc, updatedField) => {
            const [features, addons] = acc
            const [, , fieldID, fieldType] = updatedField.split(fieldNameDelimiter)
            if (fieldType === FormDataTypes.AddOn) {
                addons.push(fieldID)
            } else {
                features.push(fieldID)
            }
            return acc
        },
        [[], []]
    )
}

export const getLimitsForUpdate = (updatedFields: FormikStructure) => {
    const limits = Object.keys(updatedFields).filter((field) => {
        const [, , , fieldType] = field.split(fieldNameDelimiter)
        return fieldType === FormDataTypes.Limit
    })
    return limits.reduce((limitAcc, currentLimit) => {
        const [, , fieldID] = currentLimit.split(fieldNameDelimiter)
        return {
            ...limitAcc,
            [fieldID]: updatedFields[currentLimit],
        }
    }, {})
}

export const filterAllFromFormValues = (formValues: FormikStructure): string[] => {
    return Object.keys(formValues).filter((formKey) => {
        const columnKey = formKey.split(fieldNameDelimiter)[FieldNameParts.ColumnKey]
        return columnKey.replace(fullStopReplacement, fullStop) !== allKey
    })
}

export const filterFieldForDataIndex = (fields: string[], dataIndex: string): string[] => {
    return fields.filter((formKey) => {
        // Matches the name of the plan directly, or defaults to partial matching
        // (to consider e.g. `Essential 3` as `Essential 3.0`)
        const fieldDataIndex = formKey.split(fieldNameDelimiter)[FieldNameParts.ColumnKey]
        const escapedFieldDataIndex = fieldDataIndex.replace(fullStopReplacement, fullStop)
        return (
            escapedFieldDataIndex.includes(dataIndex) || dataIndex.includes(escapedFieldDataIndex)
        )
    })
}

export const getUpdatedColumns = (fields: string[]): string[] => {
    const columnKeys = fields.map((formKey) => {
        const key = formKey.split(fieldNameDelimiter)[FieldNameParts.ColumnKey]
        return key.replace(fullStopReplacement, fullStop)
    })
    return deDupeArray([...columnKeys])
}

export const getValueForFields = (
    fields: string[],
    formValues: FormikStructure
): FormikStructure => {
    return fields.reduce(combineArrayToObject(formValues), {})
}

type UpdatingLimits = { [limitId: string]: string | number }

export const preparePlanForUpdate = (
    plan: PlanResponse,
    featureIdsToUpdate: string[],
    addonsIdsToUpdate: string[],
    limitsToUpdate: { [limitId: string]: string | number },
    startDate?: string
): BulkUpdatePlanParams => {
    const planFeatureIds = plan.features?.map(({ id }) => id) ?? []
    const planAddOnIds = plan.addons?.map(({ id }) => id) ?? []

    const mergedFeatures = deDupeArray<string>([...featureIdsToUpdate, ...planFeatureIds])
    const mergedAddons = deDupeArray<string>([...addonsIdsToUpdate, ...planAddOnIds])

    const featuresToRemove = featureIdsToUpdate.filter((id) => planFeatureIds.includes(id))
    const addOnsToRemove = addonsIdsToUpdate.filter((id) => planAddOnIds.includes(id))

    const featuresToAdd = mergedFeatures.filter((id) => !featuresToRemove.includes(id))
    const addonsToAdd = mergedAddons.filter((id) => !addOnsToRemove.includes(id))

    const featuresBecomingAddons = featuresToAdd.filter((id) => addonsToAdd.includes(id))
    const addOnsBecomingFeatures = addOnsToRemove.filter((id) => featuresToAdd.includes(id))

    const updatedFeatures = featuresToAdd.filter((id) => !featuresBecomingAddons.includes(id))
    const updatedAddons = addonsToAdd.filter((id) => !addOnsBecomingFeatures.includes(id))
    const combinedLimits: UpdatingLimits = {
        ...plan.limits,
        ...limitsToUpdate,
    }
    const updatedLimits = Object.keys(combinedLimits)
        .filter((limit) => combinedLimits[limit] !== '')
        ?.reduce(combineArrayToObject(combinedLimits), {} as UpdatingLimits)

    const today = new Date().toISOString().split('T')[0]

    return {
        featureIds: updatedFeatures,
        addonIds: updatedAddons,
        limits: updatedLimits,
        planType: plan.type,
        country: plan.country,
        planName: plan.name,
        startDate: startDate ?? today,
        priceGeneration: plan.priceGeneration,
    }
}

export const combineFeatureFieldsForUpdate = (
    plan: PlanResponse,
    updatedFields: FormikStructure
): [string[], string[]] => {
    const planFeatureIds = plan.features?.map(({ id }) => id)
    const planAddOnIds = plan.addons?.map(({ id }) => id)
    const changedFeatures = filterUnchangedValuesInForm(
        planFeatureIds ?? [],
        updatedFields,
        FormDataTypes.Feature
    )
    const changedAddons = filterUnchangedValuesInForm(
        planAddOnIds ?? [],
        updatedFields,
        FormDataTypes.AddOn
    )

    return splitFeaturesAndAddonsIds([...changedFeatures, ...changedAddons])
}

export const isBeforeToday = (currentDate: Dayjs) =>
    currentDate.isBefore(dayjs().subtract(1, 'day'))
