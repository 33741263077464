import { ReactElement, useEffect } from 'react'
import { Button, Row } from 'antd'
import { Form, FormItem, Select } from 'formik-antd'
import { getSearchFilters, verticalFormFieldProps } from './helpers'
import { useHasPermissions } from '../../../../components/permission-guard/permission-guard'
import type { GetPlansForMarketsRequest } from '../types'
import { ViewType } from '../enums'
import { Formik, useFormikContext } from 'formik'
import { FilterLabel, FilterName } from './enums'
import { EditOutlined } from '@ant-design/icons'
import { FormCol } from './header.styles'
import type { Country } from 'countries-list'
import { useSearchParams } from 'react-router-dom'
import { bff } from '../bff'

// If the URL params are for a combobox, convert and return an array, otherwise return the value
const getParamValues = (value: string | null): string | string[] | null => {
    if (value && value.includes(',')) {
        const multiValueArray = value.split(',')
        return multiValueArray
    }
    return value
}

type HeaderProps = {
    currentView: ViewType
    updateView: (view: string) => void
    isEditing: boolean
    onEdit: () => void
}
export const Header = ({
    currentView,
    updateView,
    isEditing,
    onEdit,
}: HeaderProps): ReactElement => {
    const { submitForm, setFieldValue, values, getFieldMeta } = useFormikContext()
    const selectedCountry =
        currentView === ViewType.Market
            ? (getFieldMeta(FilterName.Market).value as Country)
            : undefined
    const hasEditPermissions = useHasPermissions(['janus'])

    const { data: markets } = bff.markets.getCountriesWithPlans.useQuery()

    const { data: planTypes } = bff.plans.getPlanTypes.useQuery({
        country: selectedCountry?.toString(),
    })

    const { data: priceGenerations } = bff.plans.getPriceGenerations.useQuery({
        countries: selectedCountry ? [selectedCountry.toString()] : [],
        types: planTypes,
    })

    const showEditButton = hasEditPermissions && !isEditing
    const filters = getSearchFilters({
        plans: planTypes,
        markets,
        priceGenerations,
        currentView,
    })
    const [searchParams, setSearchParams] = useSearchParams()

    const resetFiltersOnViewUpdate = (updatedView: ViewType) => {
        const newSearchParams = new URLSearchParams()
        setSearchParams(newSearchParams)

        setFieldValue(FilterName.Plan, '')
        setFieldValue(FilterName.Market, '')
        setFieldValue(FilterName.PriceGeneration, '')

        updateView(updatedView)
    }

    useEffect(() => {
        async function submitFormOnChange() {
            await submitForm()
        }
        submitFormOnChange()
    }, [submitForm, values])

    return (
        <Form>
            <Row gutter={16} align="bottom">
                <FormCol span={4}>
                    <FormItem
                        name={FilterName.View}
                        label={FilterLabel.View}
                        key={FilterName.View}
                        {...verticalFormFieldProps}
                    >
                        <Select
                            onSelect={resetFiltersOnViewUpdate}
                            showArrow
                            value={currentView}
                            name={FilterName.View}
                            options={[
                                {
                                    value: ViewType.Market,
                                    label: 'Market',
                                },
                                {
                                    value: ViewType.Plan,
                                    label: 'Plan',
                                },
                            ]}
                        />
                    </FormItem>
                </FormCol>

                {filters.map(({ label, name, options, mode }) => (
                    <FormCol span={name === FilterName.PriceGeneration ? 3 : 6} key={label}>
                        <FormItem name={name} label={label} key={name} {...verticalFormFieldProps}>
                            <Select
                                name={name}
                                showArrow
                                options={options}
                                mode={mode}
                                onChange={(value) => {
                                    searchParams.set(name, value)
                                    setSearchParams(searchParams)
                                }}
                            />
                        </FormItem>
                    </FormCol>
                ))}
                <FormCol span={1} offset={2}>
                    {showEditButton && (
                        <Button icon={<EditOutlined />} onClick={onEdit}>
                            Edit
                        </Button>
                    )}
                </FormCol>
            </Row>
        </Form>
    )
}

type HeaderFormikWrapperProps = HeaderProps & {
    onSearch: (request: GetPlansForMarketsRequest) => void
}

const HeaderFormikWrapper = ({
    currentView,
    updateView,
    isEditing,
    onEdit,
    onSearch,
}: HeaderFormikWrapperProps): ReactElement => {
    const [searchParams] = useSearchParams()
    const initialSearchValues: GetPlansForMarketsRequest = {
        [FilterName.Market]: getParamValues(searchParams.get(FilterName.Market)) ?? '',
        [FilterName.Plan]: getParamValues(searchParams.get(FilterName.Plan)) ?? '',
        [FilterName.PriceGeneration]:
            getParamValues(searchParams.get(FilterName.PriceGeneration)) ?? '',
    }

    return (
        <Formik initialValues={initialSearchValues} onSubmit={onSearch}>
            <Header
                currentView={currentView}
                updateView={updateView}
                isEditing={isEditing}
                onEdit={onEdit}
            />
        </Formik>
    )
}
export default HeaderFormikWrapper
